import { observable } from 'mobx';


export class ProjectFormStore {
  @observable opened = false;

  @observable projectId = 0;

  @observable action = '';
}


const projectFormStore = new ProjectFormStore();

export default projectFormStore;

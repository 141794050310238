import React from 'react';
import { withRouter } from 'react-router-dom';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import {
  Form, Input, Button,
  AutoComplete, message,
} from 'antd';

import Base from 'components/Base';
import siteService from 'service/SiteService';

const FormItem = Form.Item;
@withRouter
@inject('appStore')
@observer
class LogForm extends Base {
  @observable projects = [];

  handleSubmit = (e) => {
    const { logId, form, onFormSubmit } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const data = Object.assign(values, {
          Uid: this.s('app').userId,
        });
        data.Pid = Number(data.Pid);
        data.ID = Number(logId);
        this.s('app').loading();
        siteService
          .api(this.s('app').token)
          .updateLog(JSON.stringify(data))
          .then(this.handleResponse)
          .then(() => {
            message.success('Wpis został poprawnie dodany!');
            this.s('app').loaded();
            this.s('app').update();
            onFormSubmit();
          })
          .catch(this.handleResponseError('Błąd podczas zapisywania wpisu!'));
      }
    });
  }

  componentDidMount() {
    this.loadProjects(this.loadLog, 0, true);
  }

  loadLog = () => {
    const { logId, form } = this.props;
    siteService
      .api(this.s('app').token)
      .getLogById(logId)
      .then(this.handleResponse)
      .then((data) => {
        const item = data[0];
        const { Comment, Pid } = item;
        form.setFieldsValue({
          Pid: String(Pid),
          Comment,
        });
        this.s('app').loaded();
      })
      .catch(this.handleResponseError('Błąd podczas ładowania wpisu!'));
  }

  handleSearch = (value, option) => option.props.children
    .toLowerCase().indexOf(value.toLowerCase()) >= 0;

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="log-form">
        <FormItem>
          {getFieldDecorator('Pid', {
            rules: [{ required: true, message: 'Wybierz projekt!' }],
          })(
            <AutoComplete
              dataSource={this.projects}
              style={{ width: '100%' }}
              filterOption={this.handleSearch}
              placeholder="Nazwa projektu"
            />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('Comment', {
            rules: [{ required: false, message: 'Dodaj komentarz' }],
          })(
            <Input
              style={{ width: '100%' }}
              placeholder="Komentarz"
            />,
          )}
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            style={{ width: '100%' }}
            htmlType="submit"
            className="login-form-button"
          >
            Zapisz
          </Button>
        </FormItem>
      </Form>
    );
  }

  render() {
    return (
      <div>
        { this.renderForm() }
      </div>);
  }
}

export default Form.create()(LogForm);

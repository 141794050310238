import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { observable } from 'mobx';
import {
  Row, Col, Divider,
} from 'antd';

import Base from 'components/Base';
import TimesheetDayForm from 'pages/Timesheet/components/TimesheetDayForm/TimesheetDayForm';
import InOutList from 'pages/Timesheet/components/InOutList/InOutList';
import LogsForDay from 'pages/Timesheet/components/LogForDay/LogsForDay';

import './Timesheet.page.scss';

@inject('appStore', 'timesheetStore')
@observer
class Timesheet extends Base {
  @observable day = '';

  constructor(props) {
    super(props);
    this.s('timesheet').reset();
    const day = moment(this.props.match.params.day, 'YYYY-MM-DD', true);
    if (day.isValid()) {
      this.s('timesheet').Day = day.format('YYYY-MM-DD');
    }
  }

  componentDidMount() {
    this.s('app').selectedItem = 'timesheet';
  }

  render() {
    return (
      <div className="TimesheetPage">
        <Row>
          <Col lg={6}>
            <h1>Dzień pracy</h1>
            <Divider type="vertical" />
            <TimesheetDayForm />
          </Col>
          <Col lg={2} className="verticalDivider">
            <Divider type="vertical" />
          </Col>
          <Col lg={6}>
            <h2>Wyjścia</h2>
            <InOutList />
          </Col>
          <Col lg={2} className="verticalDivider">
            <Divider type="vertical" />
          </Col>
          <Col lg={6}>
            <LogsForDay />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Timesheet;

import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { List, message } from 'antd';

import Base from 'components/Base';
import siteService from 'service/SiteService';
import LastEntriesItem from 'components/LastEntriesItem/LastEntriesItem';
import LogModal from 'components/LogModal/LogModal';

@inject('appStore')
@observer
class LastEntries extends Base {
  @observable logs = [];

  @observable openedLogId = 0;

  loadLog = () => {
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .getLogLimit(20)
      .then(this.handleResponse)
      .then((son) => {
        this.logs = son.map(item => (
          <LastEntriesItem
            key={item.ID}
            item={item}
            onDelete={this.handleDelete}
            onEdit={this.handleEdit}
            projectName={this.getProject(item.Pid)}
          />));
        this.s('app').loaded();
      })
      .catch(this.handleResponseError('Błąd ładowania danych!'));
  }

  handleDelete = (id) => {
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .deleteLog(id)
      .then(this.handleResponse)
      .then(() => {
        this.s('app').loaded();
        this.s('app').update();
        message.success('Wpis został poprawnie usunięty.');
      })
      .catch(this.handleResponseError('Błąd podczas usuwania!'));
  }

  handleEdit = (id) => {
    this.s('app').logModalOpened = true;
    this.openedLogId = id;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateCount === this.props.updateCount) {
      return;
    }
    this.loadLog();
  }

  getProject = (id) => {
    const project = this.projects.find(el => Number(el.value) === Number(id));
    if (project === undefined) {
      return 'Brak nazwy';
    }
    return project.text;
  }

  componentDidMount() {
    this.loadProjects(this.loadLog);
  }

  render() {
    return (
      <>
        <List
          dataSource={this.logs}
          renderItem={item => item}
        />
        <LogModal
          logId={this.openedLogId}
        />
      </>
    );
  }
}

export default LastEntries;

import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import {
  Divider, message,
} from 'antd';

import Base from 'components/Base';
import ReportForm from 'pages/Report/components/ReportForm/ReportForm';
import ReportTable from 'pages/Report/components/ReportTable/ReportTable';

@inject('appStore')
@observer
class Report extends Base {
  componentDidMount() {
    this.s('app').selectedItem = 'report';
  }

  @observable data = {};

  handleSearch = (err, values) => {
    if (err !== null) {
      message.warn('Podaj poprawną datę!');
      return;
    }
    const data = {
      TimeSpanType: Number(values.time),
    };
    if (Array.isArray(values.project)) {
      data.Project = values.project.map(value => Number(value));
    } else {
      data.Project = [Number(values.project)];
    }
    if (Array.isArray(values.user)) {
      data.User = values.user.map(value => Number(value));
    } else {
      data.User = [Number(values.user)];
    }
    if (data.TimeSpanType === 1) {
      data.Date = values['time-month'].format('YYYY-MM');
    }
    if (data.TimeSpanType === 2) {
      data.StartDate = values['time-range'][0].format('YYYY-MM-DD');
      data.EndDate = values['time-range'][1].format('YYYY-MM-DD');
    }
    this.data = data;
    this.s('app').update();
  }

  render() {
    return (
      <div>
        <ReportForm onSearchClick={this.handleSearch} type="log" />
        <Divider />
        <ReportTable updateCount={this.s('app').updateCount} data={toJS(this.data)} />
      </div>
    );
  }
}

export default Report;

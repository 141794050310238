import jwt from 'jsonwebtoken';

export function isJwtExpired(token) {
  const data = jwt.decode(token);
  if (data === null) {
    return false;
  }
  const now = Math.floor(Date.now() / 1000);
  return data.exp <= now;
}

export default{
  isJwtExpired,
};

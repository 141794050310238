import { observable, computed, action } from 'mobx';
import jwt from 'jsonwebtoken';


export class AppStore {
  @observable token = null;

  @observable logModalOpened = false;

  @observable userModalOpened = false;

  @observable selectedItem = 'login';

  @observable loadingCount = 0;

  @observable homepageOpenedForm = 1;

  @computed get isLoading() {
    return (this.loadingCount > 0);
  }

  @action.bound
  loading() {
    this.loadingCount += 1;
  }

  @action.bound
  loaded() {
    if (this.loadingCount <= 0) {
      return;
    }
    this.loadingCount -= 1;
  }

  @observable updateCount = 0;

  @action.bound
  update() {
    this.updateCount += 1;
  }

  @computed get userId() {
    if (this.token === null) {
      return '';
    }
    const data = jwt.decode(this.token);
    return data.uid;
  }

  @computed get userName() {
    if (this.token === null) {
      return '';
    }
    const data = jwt.decode(this.token);
    return data.name;
  }

  @computed get superUser() {
    if (this.token === null) {
      return false;
    }
    const data = jwt.decode(this.token);
    return data.su === true;
  }
}


const appStore = new AppStore();

export default appStore;

/* global VERSION */

import React from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import {
  Icon, Menu, Spin, Layout, message,
} from 'antd';

import Base from 'components/Base';
import { getTimerForm } from 'utils/storage';

import './AppHeader.scss';

const { Header } = Layout;

@inject('appStore')
@observer
class AppHeader extends Base {
  handleLogout = () => {
    const form = getTimerForm();
    if (form !== '' && form !== null && form !== 'null') {
      const json = JSON.parse(form);
      if (json.started || json.running) {
        message.error('Jeśli chcesz się wylogować, wyłącz najpierw minutnik!');
      } else {
        this.logout();
      }
    } else {
      this.logout();
    }
  }

  render() {
    const antIcon = <Icon type="sync" style={{ fontSize: 28, color: '#52c41a' }} spin />;
    return (

      <Header className="AppHeader">
        <Menu theme="dark" mode="horizontal" selectedKeys={[this.s('app').selectedItem]}>
          <Menu.Item key="home">
            <Link to="/">
              <Icon type="home" />
            Home
            </Link>
          </Menu.Item>
          { (this.s('app').token === null)
        || (
        <Menu.SubMenu title={(
          <span className="submenu-title-wrapper">
            <Icon type="calendar" style={{ fontSize: '20px' }} />
            Logowanie czasu
          </span>
          )}
        >
          <Menu.Item key="add_log"><Link to="/add_log">Dodaj</Link></Menu.Item>
          <Menu.Item key="log_browser"><Link to="/log_browser">Przeglądaj</Link></Menu.Item>
          <Menu.Item key="report"><Link to="/report">Raport</Link></Menu.Item>
        </Menu.SubMenu>
        ) }
          { (this.s('app').token === null)
        || (
        <Menu.SubMenu title={(
          <span className="submenu-title-wrapper">
            <Icon type="clock-circle" style={{ fontSize: '20px' }} />
            Dziennik wejść
          </span>
          )}
        >
          <Menu.Item key="timesheet"><Link to="/timesheet">Dodaj</Link></Menu.Item>
          <Menu.Item key="timesheet_report"><Link to="/timesheet_report">Raport</Link></Menu.Item>
        </Menu.SubMenu>
        ) }
          { (!this.s('app').superUser) || (
          <Menu.SubMenu title={(
            <span className="submenu-title-wrapper">
              <Icon type="setting" style={{ fontSize: '20px' }} />
            Administracja
            </span>
          )}
          >
            { (!this.s('app').superUser) || <Menu.Item key="users"><Link to="/users">Użytkownicy</Link></Menu.Item> }
            { (!this.s('app').superUser) || <Menu.Item key="projects"><Link to="/projects">Projekty</Link></Menu.Item> }
          </Menu.SubMenu>
          ) }
          { (this.s('app').token !== null) || <Menu.Item key="login"><Link to="/login">Logowanie</Link></Menu.Item> }
          <Menu.Item key="loading"><Spin spinning={this.s('app').isLoading} indicator={antIcon} /></Menu.Item>
          { (this.s('app').token === null) || <Menu.Item className="toRight" onClick={this.handleLogout} key="logout">Wyloguj</Menu.Item> }
          { (this.s('app').token === null) || <Menu.Item className="toRight textOnly">{this.s('app').userName}</Menu.Item> }
          { (this.s('app').token === null) || <Menu.Item className="toRight textOnlyDark">{VERSION}</Menu.Item> }
        </Menu>

      </Header>
    );
  }
}

export default AppHeader;

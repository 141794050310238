import React from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { List } from 'antd';
import Base from 'components/Base';
import TimesheetForm from 'pages/Timesheet/components/TimesheetForm/TimesheetForm';
import InOutListItem from 'pages/Timesheet/components/InOutListItem/InOutListItem';

@inject('appStore', 'timesheetStore')
@observer
class InOutList extends Base {
  render() {
    const data = toJS(this.s('timesheet').InOut);
    return (
      <List
        dataSource={data}
        renderItem={item => <InOutListItem item={item} />}
        footer={<TimesheetForm dayId={this.s('timesheet').DayId} />}
        locale={{
          emptyText: 'Brak wyjść w czasie pracy',
        }}
      />
    );
  }
}

export default InOutList;

import React from 'react';
import {
  Modal, Button,
} from 'antd';
import { inject, observer } from 'mobx-react';

import Base from 'components/Base';
import UserForm from 'pages/Users/components/UserForm/UserForm';

@inject('appStore', 'userFormStore')
@observer
class UserModal extends Base {
  handleCancel = () => {
    this.s('userForm').opened = false;
  }

  render() {
    return (
      <Modal
        title="Użytkownik"
        visible={this.s('userForm').opened}
        onCancel={this.handleCancel}
        maskClosable={false}
        destroyOnClose
        footer={[
          <Button key="back" onClick={this.handleCancel}>Porzuć zmiany i zamknij</Button>,
        ]}
      >
        <UserForm />
      </Modal>
    );
  }
}

export default UserModal;

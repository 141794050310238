/* global window */
import React from 'react';
import {
  Form, Input, Checkbox, message, Button,
} from 'antd';
import { observable, computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import Base from 'components/Base';
import siteService from 'service/SiteService';

const FormItem = Form.Item;

@inject('appStore', 'projectFormStore')
@observer
class UserForm extends Base {
  @observable enabled = false;

  @observable isVisible = false;

  @computed get checkboxVisible() {
    return (this.s('projectForm').action !== 'new');
  }

  @computed get action() {
    return this.s('projectForm').action;
  }

  @observable roles = [];

  handleSave = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (this.s('projectForm').action === 'new') {
        this.handleCreate(values);
      } else {
        this.handleUpdate(values);
      }
    });
  }

  handleCreate = (values) => {
    const data = Object.assign({}, values);
    data.Disabled = this.enabled === false;
    data.Hidden = this.isVisible === false;
    siteService
      .api(this.s('app').token)
      .createProject(JSON.stringify(data))
      .then(this.handleResponse)
      .then((json) => {
        if (json.ValidationError === true) {
          message.warn('Projekt o tym kluczu już istnieje!');
          return;
        }
        this.s('app').update();
        message.success('Dodano nowy projekt!');
        this.s('projectForm').opened = false;
      })
      .catch(this.handleResponseError('Error!'));
  }

  handleUpdate = (values) => {
    const data = Object.assign({}, values);
    data.ID = this.s('projectForm').projectId;
    data.Disabled = this.enabled === false;
    data.Hidden = this.isVisible === false;
    siteService
      .api(this.s('app').token)
      .updateProject(JSON.stringify(data))
      .then(this.handleResponse)
      .then((json) => {
        if (json.ValidationError === true) {
          message.warn('Błąd podszas aktualizacji projektu!');
          return;
        }
        this.s('app').update();
        message.success('Dane zaktualizowane!');
        this.s('projectForm').opened = false;
      })
      .catch(this.handleResponseError('Error!'));
  }

  handleCancel = () => {
    this.s('projectForm').opened = false;
  }

  handleCheckbox = (e) => {
    if (e.target.name === 'Enabled') {
      this.enabled = e.target.checked;
      return;
    }
    this.isVisible = e.target.checked;
  }

  componentDidMount = () => {
    window.form = this.props.form;
    this.props.form.resetFields();
    this.isVisible = false;
    this.enabled = false;
    if (this.s('projectForm').action !== 'edit') {
      return;
    }
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .getProject(this.s('projectForm').projectId)
      .then(this.handleResponse)
      .then((json) => {
        this.s('app').loaded();
        this.props.form.setFieldsValue({
          Name: json.Name,
          Description: json.Description,
        });
        this.enabled = json.Disabled === false;
        this.isVisible = json.Hidden === false;
      })
      .catch(this.handleResponseError('Nie można załadować danych!'));
  }

  isPasswordNotDisabled() {
    if (this.passwordDisabled) {
      return true;
    }
    if (this.s('projectForm').action === 'new') {
      return true;
    }
    return false;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        <FormItem>
          {getFieldDecorator('Name', {
            rules: [{ required: true, message: 'Podaj nazwę projektu!' }],
          })(
            <Input autoComplete="off" type="text" placeholder="Nazwa" />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('Description', {
            rules: [{ required: true, message: 'Opis' }],
          })(
            <Input autoComplete="off" placeholder="Opis" />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('Enabled', {
          })(
            <Checkbox
              name="Enabled"
              onChange={this.handleCheckbox}
              checked={this.enabled}
            >
              Aktywny
            </Checkbox>,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('Visible', {
          })(
            <Checkbox
              name="Visible"
              onChange={this.handleCheckbox}
              checked={this.isVisible}
            >
              Widoczny
            </Checkbox>,
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" style={{ width: '100%' }} onClick={this.handleSave}>Zapisz</Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(UserForm);

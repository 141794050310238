import React from 'react';
import { Calendar, Badge } from 'antd';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import Base from 'components/Base';
import siteService from 'service/SiteService';

import './TimesheetCalendar.scss';

@withRouter
@inject('appStore')
@observer
class TimesheetCalendar extends Base {
  @observable selectedDate = '';

  @observable data = [];

  getData = () => {
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .getTimesheetLog(this.selectedDate)
      .then(this.handleResponse)
      .then((json) => {
        this.data = json;
        this.s('app').loaded();
      })
      .catch(this.handleResponseError('Nie można pobrać danych!'));
  }

  handleChange = (date) => {
    const month = date.format('YYYY-MM');
    if (month === this.selectedDate) {
      return;
    }
    this.selectedDate = month;
    this.getData();
  }

  hansleSelect = (data) => {
    this.props.history.push(`/timesheet/${data.format('YYYY-MM-DD')}`);
  }

  renderDay = (date) => {
    const month = date.month();
    const currentMonth = moment(this.selectedDate, 'YYYY-MM').month();
    if (month !== currentMonth) {
      return '';
    }
    const day = date.date();
    if (this.data[day] === false || this.data[day] === undefined) {
      return <Badge status="error" />;
    }
    return <Badge status="success" />;
  }

  componentDidMount() {
    this.selectedDate = moment().format('YYYY-MM');
    this.getData();
  }

  render() {
    return (
      <div className="TimesheetCalendar">
        <h2>Dziennik wejść</h2>
        {this.s('app').isLoading
        || (
        <Calendar
          fullscreen={false}
          onChange={this.handleChange}
          onSelect={this.hansleSelect}
          dateCellRender={this.renderDay}
          value={moment(this.selectedDate, 'YYYY-MM')}
        />
        )
      }
      </div>
    );
  }
}

export default TimesheetCalendar;

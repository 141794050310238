/* global window */

export function getSession() {
  const ret = window.localStorage.getItem('token');
  if (ret === 'null') {
    return null;
  }
  return ret;
}

export function getOpenedForm() {
  const ret = window.localStorage.getItem('openedForm');
  if (ret === 'null') {
    return 1;
  }
  const val = Number(ret);
  if (Number.isNaN(val) || val <= 0) {
    return 1;
  }
  return val;
}

export function setOpenedForm(formId) {
  window.localStorage.setItem('openedForm', formId);
}

export function getTimerForm() {
  return window.localStorage.getItem('timerForm');
}

export function setSession(token) {
  window.localStorage.setItem('token', token);
}

export function setTimerForm(form) {
  window.localStorage.setItem('timerForm', form);
}

export function clearTimerForm() {
  window.localStorage.setItem('timerForm', null);
}

export function clearSession() {
  setSession(null);
}

export default {
  getSession,
  clearSession,
  setSession,
  clearTimerForm,
  setTimerForm,
  getTimerForm,
  getOpenedForm,
  setOpenedForm,
};

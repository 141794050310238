/* global encodeURIComponent */
import React from 'react';
import {
  Table, Button,
} from 'antd';
import moment from 'moment';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import fileSaver from 'file-saver';

import Base from 'components/Base';
import siteService from 'service/SiteService';
import { prettyMinutes } from 'utils/moment';

import './ReportTable.scss';

@inject('appStore')
@observer
class ReportTable extends Base {
  @observable loading = false;

  @observable report = [];

  @observable columns = [{
    title: '-',
    dataIndex: 'ProjectName',
    key: 'pname',
  }];

  handleDownloadXlsxBackend = () => {
    this.s('app').loading();
    siteService.api(this.s('app').token)
      .getDownloadToken('log')
      .then(this.handleResponse)
      .then(((dToken) => {
        const data = encodeURIComponent(JSON.stringify(this.props.data));
        return siteService.downloadLog(data, dToken);
      }))
      .then(async (r) => {
        const data = await r.blob();
        fileSaver.saveAs(data, `raport-log-${moment().format('YYYY-MM-DD_HH-mm')}.xlsx`);
        this.s('app').loaded();
      })
      .catch(this.handleResponseError('Nie mozna pobrać!'));
  }

  formatMinutes = v => (
    <span>
      {prettyMinutes(v, true)}
    </span>
  )

  prepareColumns = (columns) => {
    const columnsKeys = Object.keys(columns);
    const newColumns = [];
    newColumns.push({
      title: () => (
        <>
          <Button onClick={this.handleDownloadXlsxBackend}>Pobierz</Button>
        </>
      ),
      dataIndex: 'ProjectName',
      key: 'pname',
    });
    newColumns.push({
      title: 'Suma',
      dataIndex: 'TotalCount',
      key: 'pcount',
      render: this.formatMinutes,
    });
    for (let i = 0; i < columnsKeys.length; i += 1) {
      newColumns.push({
        title: columns[columnsKeys[i]],
        dataIndex: `uname${columnsKeys[i]}`,
        key: `uname${i}`,
        render: this.formatMinutes,
      });
    }
    this.columns.replace(newColumns);
    return columnsKeys;
  }

  prepareData = (json, cKeys) => {
    const stamp = (new Date()).getTime();
    const newData = [];
    const dataKeys = Object.keys(json.Data);
    for (let i = 0; i < dataKeys.length; i += 1) {
      const rowData = json.Data[dataKeys[i]];
      const row = { ProjectName: json.Projects[dataKeys[i]], TotalCount: 0 };
      let totalCount = 0;
      for (let j = 0; j < cKeys.length; j += 1) {
        const colKey = cKeys[j];
        if (!(colKey in rowData)) {
          row[`uname${colKey}`] = 0;
        } else {
          row[`uname${colKey}`] = rowData[colKey];
          totalCount += Number(rowData[colKey]);
        }
      }
      row.TotalCount = totalCount;
      row.key = `${stamp}-${i}`;
      newData.push(row);
    }
    return newData;
  }

  componentDidMount() {
    this.loadReports();
  }

  loadReports() {
    this.loading = true;
    this.s('app').loading();
    this.loadReport((json) => {
      this.columns = [];
      const cKeys = this.prepareColumns(json.Columns);
      const newData = this.prepareData(json, cKeys);
      this.report.replace(newData);
      this.loading = false;
      this.s('app').loaded();
    });
  }

  loadReport = (callback) => {
    siteService
      .api(this.s('app').token)
      .getReport(JSON.stringify(this.props.data))
      .then(this.handleResponse)
      .then((son) => {
        callback(son);
      })
      .catch(this.handleResponseError('Error!'));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateCount === this.props.updateCount) {
      return;
    }
    this.loadReports();
  }

  renderTable() {
    return (
      <Table className="ReportTable" dataSource={this.report} columns={this.columns} pagination={false} />
    );
  }

  render() {
    return (
      <div>
        {this.loading ? <span /> : this.renderTable()}
      </div>
    );
  }
}

export default ReportTable;

/* global window */
import React from 'react';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import {
  Form, Icon, Input, Button, Card,
  message, Row, Col,
} from 'antd';

import Base from 'components/Base';
import loginService from 'service/SiteService';

import './Login.scss';

const FormItem = Form.Item;

@inject('appStore')
@observer
class Login extends Base {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.s('app').loading();
        loginService.login(values.username, values.password)
          .then((resp) => {
            if (resp.status !== 200) {
              return Promise.reject();
            }
            return resp.json();
          })
          .then((son) => {
            this.s('app').token = son.token;
            window.localStorage.setItem('token', son.token);
            this.s('app').loaded();
          })
          .catch(() => {
            this.s('app').loaded();
            message.error('Niepoprawne dane logowania!');
          });
      }
    });
  }

  componentDidMount() {
    this.s('app').selectedItem = 'login';
  }

  renderLoginForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <FormItem>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Podaj nazwę użytkownika!' }],
          })(
            <Input name="username" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Nazwa użytkownika" />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Podaj swoje hasło!' }],
          })(
            <Input prefix={<Icon name="password" type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Hasło" />,
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Zaloguj
          </Button>
        </FormItem>
      </Form>
    );
  }

  renderLink() {
    this.a = 1;
    return (
      <Card title="Logowanie" bordered={false} style={{ width: 300 }}>
        <p>Jesteś zalogowany!</p>
        <Link to="/">Idź do strony głównej</Link>
      </Card>);
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={10} xs={0} />
          <Col md={4} xs={24}>
            { (this.s('app').token == null) ? this.renderLoginForm() : this.renderLink()}
          </Col>
          <Col md={10} xs={0} />
        </Row>
      </div>
    );
  }
}

export default Form.create()(Login);

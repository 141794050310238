import React from 'react';
import { observer, inject } from 'mobx-react';
import {
  Button, Divider, message,
} from 'antd';
// import { observable } from 'mobx';
import Base from 'components/Base';
import ProjectTable from 'pages/Projects/components/ProjectTable/ProjectTable';
import ProjectModal from 'pages/Projects/components/ProjectModal/ProjectModal';
import siteService from 'service/SiteService';

@inject('appStore', 'projectFormStore')
@observer
class Projects extends Base {
  componentDidMount() {
    this.s('app').selectedItem = 'projects';
  }

  handleDelete = (id) => {
    siteService
      .api(this.s('app').token)
      .deleteProject(id)
      .then(this.handleResponse)
      .then((json) => {
        if (json.ValidationError === true) {
          message.warn('Nie można usunąć projektu!');
          return;
        }
        message.success('Projekt poprawnie usunięty!');
        this.s('app').update();
      })
      .catch(this.handleResponseError('Błąd systemowy!'));
  }

  handleAction = (id, action) => {
    if (action === 'delete') {
      this.handleDelete(id);
      return;
    }
    this.s('projectForm').action = action;
    this.s('projectForm').projectId = id;
    this.s('projectForm').opened = true;
  }

  render() {
    return (
      <div>
        <ProjectModal />
        <Button
          type="primary"
          onClick={() => this.handleAction(0, 'new')}
        >
        Dodaj projekt
        </Button>
        <Divider type="horizontal" />
        <ProjectTable
          onAction={this.handleAction}
          updateCount={this.s('app').updateCount}
        />
      </div>
    );
  }
}

export default Projects;

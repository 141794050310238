import React from 'react';
import { observable } from 'mobx';
import { TimePicker, Button } from 'antd';
import { observer } from 'mobx-react';

@observer
class ConfirmTimePicker extends React.Component {
  @observable opened = false;

  handleOpenChange = (open) => {
    this.opened = open;
  }

  handleClose = () => {
    this.opened = false;
  }

  render() {
    return (
      <TimePicker
        open={this.opened}
        {... this.props}
        onOpenChange={this.handleOpenChange}
        addon={() => (
          <Button size="small" type="primary" onClick={this.handleClose}>
            Ok
          </Button>
        )}
      />
    );
  }
}

export default ConfirmTimePicker;

import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Timeline, Button, Divider } from 'antd';
import { withRouter } from 'react-router-dom';
import Base from 'components/Base';

import siteService from 'service/SiteService';

@withRouter
@inject('appStore')
@observer
class History extends Base {
  static parseTime(minutes) {
    if (minutes < 60) {
      return `${minutes}m`;
    }
    return `${Math.floor(minutes / 60)}h ${minutes % 60}m`;
  }

  @observable history = [];

  updateHistory = () => {
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .getHistory()
      .then(this.handleResponse)
      .then((son) => {
        this.s('app').loaded();
        if (Number(son.length) > 0) {
          this.history = son.map((row) => {
            const data = Object.assign({}, row);
            data.Day = data.Day.substr(0, 10);
            return data;
          });
        } else {
          this.history = [{
            Day: '',
            Duration: 0,
            NoEntry: true,
          }];
        }
      })
      .catch(this.handleResponseError('Błąd podszas ładowania.'));
  }

  componentDidMount() {
    this.updateHistory();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateCount === this.props.updateCount) {
      return;
    }
    this.updateHistory();
  }

  static renderRow(entry) {
    if (entry.NoEntry === true) {
      return (
        <Timeline.Item key={entry.Day}>
          Brak wpisów...
        </Timeline.Item>
      );
    }
    return (
      <Timeline.Item key={entry.Day}>
        {entry.Day}
        &nbsp;-&nbsp;
        {History.parseTime(entry.Duration)}
      </Timeline.Item>
    );
  }

  handleNew = () => {
    this.props.history.push('/add_log');
  }

  render() {
    const { showButton } = this.props;
    return (
      <>
        {(showButton === undefined || showButton === true)
        && <Button onClick={this.handleNew}>Dodaj nowy</Button>}
        <Divider />
        <Timeline>
          {this.history.map(entry => History.renderRow(entry))}
        </Timeline>
      </>
    );
  }
}

export default History;

import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import {
  Select, Form, Button, Checkbox, DatePicker, message,
} from 'antd';
import moment from 'moment';

import siteService from 'service/SiteService';
import Base from 'components/Base/Base';

import './LogBrowserForm.scss';

const FormItem = Form.Item;

@inject('appStore')
@observer
class LogBrowserForm extends Base {
  @observable users = []

  @observable dateVisible = false;

  loadUsers = (callback) => {
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .getUsers('log')
      .then(this.handleResponse)
      .then((son) => {
        this.users = son;
        this.s('app').loaded();
        if (typeof callback === 'function') {
          callback();
        }
      })
      .catch(this.handleResponseError('Nie udało się pobrać użytkowników'));
  }

  handleFormSubmit = (e) => {
    const { onFormSubmit } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, data) => {
      const options = data;
      if (err !== null) {
        message.warn('Podaj poprawną datę!');
        return;
      }
      options.Day = data.Day.startOf('day');
      if (typeof onFormSubmit === 'function') {
        onFormSubmit(options);
      }
    });
  }

  handleLimitDateChange = (e) => {
    this.dateVisible = e.target.checked;
  }

  componentDidMount() {
    this.s('app').selectedItem = 'log_browser';
    this.loadUsers(() => {
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form layout="inline" onSubmit={this.handleFormSubmit} className="LogBrowserForm">
          <FormItem>
            {getFieldDecorator('Uid', {
              initialValue: this.s('app').userId,
            })(
              <Select className="LogBrowserForm__UserSelect">
                {this.users.map((user => (
                  <Select.Option key={user.Uid} value={user.Uid}>{user.Name}</Select.Option>
                )))}
              </Select>,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('LimitDate', {
              initialValue: false,
            })(
              <Checkbox onChange={this.handleLimitDateChange}>Wyświetl od dnia</Checkbox>,
            )}
          </FormItem>
          <FormItem style={{ display: this.dateVisible ? 'inline-block' : 'none' }}>
            {getFieldDecorator('Day', {
              initialValue: moment(),
            })(
              <DatePicker />,
            )}
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit">Pokaż</Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default Form.create()(LogBrowserForm);

/* global window */
import React from 'react';
import {
  Form, Input, Checkbox, Select, message, Button, Divider,
} from 'antd';
import { observable, computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import Base from 'components/Base';
import siteService from 'service/SiteService';

const FormItem = Form.Item;
const { Option } = Select;

const USER_ROLE = 'fec40f4c-dc56-11e8-9f8b-f2801f1b9fd1';

const RIGHTS = [
  'Brak',
  'Raport wejść/wyjść dla wszystkich użytkowników',
];

@inject('appStore', 'userFormStore')
@observer
class UserForm extends Base {
  @observable passwordRequired = false;

  @observable enabled = false;

  @observable selecedRole = 'fec40f4c-dc56-11e8-9f8b-f2801f1b9fd1';

  @computed get checkboxVisible() {
    return (this.s('userForm').action !== 'new');
  }

  @computed get action() {
    return this.s('userForm').action;
  }

  @observable roles = [];

  handleSave = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const data = values;
      data.Rights = values.Rights ? 1 : 0;
      if (this.s('userForm').action === 'new') {
        this.handleCreate(data);
      } else {
        this.handleUpdate(data);
      }
    });
  }

  handleCreate = (values) => {
    const data = Object.assign({}, values);
    data.ChangePassword = true;
    data.Enabled = values.Enabled === true ? 1 : 0;
    siteService
      .api(this.s('app').token)
      .createUser(JSON.stringify(data))
      .then(this.handleResponse)
      .then((json) => {
        if (json.ValidationError === true) {
          message.warn('Ten email jest już używany!');
          return;
        }
        this.s('app').update();
        message.success('Dodano nowego użytkownika!');
        this.s('userForm').opened = false;
      })
      .catch(this.handleResponseError('Error!'));
  }

  handleUpdate = (values) => {
    const data = Object.assign({}, values);
    data.ChangePassword = this.passwordRequired;
    data.Uid = this.s('userForm').userId;
    data.Enabled = values.Enabled === true ? 1 : 0;
    siteService
      .api(this.s('app').token)
      .updateUser(JSON.stringify(data))
      .then(this.handleResponse)
      .then((json) => {
        if (json.ValidationError === true) {
          message.warn('Błąd podszas aktualizacji użytkownika!');
          return;
        }
        this.s('app').update();
        message.success('Dane zaktualizowane!');
        this.s('userForm').opened = false;
      })
      .catch(this.handleResponseError('Error!'));
  }

  handleCancel = () => {
    this.s('userForm').opened = false;
  }

  handleCheckbox = (e) => {
    if (e.target.name === 'Enabled') {
      this.enabled = e.target.checked;
      return;
    }
    this.passwordRequired = e.target.checked;
  }

  componentDidMount = () => {
    window.form = this.props.form;
    this.props.form.resetFields();
    this.loadGroups(() => {
      if (this.s('userForm').action !== 'edit') {
        this.selecedRole = 'fec40f4c-dc56-11e8-9f8b-f2801f1b9fd1';
        return;
      }
      this.s('app').loading();
      siteService
        .api(this.s('app').token)
        .getUser(this.s('userForm').userId)
        .then(this.handleResponse)
        .then((json) => {
          this.s('app').loaded();
          this.props.form.setFieldsValue({
            Name: json.Name,
            Email: json.Email,
            Role: json.Role,
            Rights: json.Rights,
            Enabled: json.Enabled === 1,
          });
          this.selecedRole = json.Role;
          this.enabled = json.Enabled === 1;
        })
        .catch(this.handleResponseError('Nie można załadować danych!'));
    });
  }

  loadGroups = (callback) => {
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .getRoles()
      .then(this.handleResponse)
      .then((son) => {
        this.roles = son;
        this.s('app').loaded();
        if (typeof callback === 'function') {
          callback();
        }
      })
      .catch(this.handleResponseError('Nie można załadować danych'));
  }

  isPasswordNotDisabled() {
    if (this.passwordDisabled) {
      return true;
    }
    if (this.s('userForm').action === 'new') {
      return true;
    }
    return false;
  }

  handleRoleChange = (v) => {
    this.selecedRole = v;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        <FormItem>
          {getFieldDecorator('Name', {
            rules: [{ required: true, message: 'Podaj imię i nazwisko!' }],
          })(
            <Input autoComplete="off" type="text" placeholder="Imię i nazwisko" />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('Email', {
            rules: [
              { type: 'email', required: true, message: 'Podaj email!' }],
          })(
            <Input autoComplete="off" type="email" placeholder="Email" />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('Role', {
            rules: [{ required: true, message: 'Wybierz role!' }],
          })(
            <Select onChange={this.handleRoleChange}>
              {this.roles.map(role => (
                <Option key={role.Id} value={role.Id}>
                  {role.Name}
                </Option>
              ))}
            </Select>,
          )}
        </FormItem>
        <FormItem style={{ display: this.selecedRole === USER_ROLE ? 'block' : 'none' }}>
          <Divider>Uprawnienia</Divider>
          {getFieldDecorator('Rights', {
            initialValue: 0,
          })(
            <Select>
              {
                RIGHTS.map((value, i) => (
                  <Option value={i}>{value}</Option>
                ))
              }
            </Select>,
          )}
          <Divider />
        </FormItem>
        <FormItem>
          {getFieldDecorator('Enabled', {
          })(
            <Checkbox
              name="Enabled"
              onChange={this.handleCheckbox}
              checked={this.enabled}
            >
              Aktywny
            </Checkbox>,
          )}
        </FormItem>
        <FormItem style={{ display: (this.checkboxVisible) ? 'block' : 'none' }}>
          {getFieldDecorator('ChangePassword', {
          })(
            <Checkbox
              name="ChangePassword"
              onChange={this.handleCheckbox}
              checked={this.passwordRequired}
            >
              Zmień hasło
            </Checkbox>,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('NewPassword', {
            rules: [{ required: this.passwordRequired, message: 'Podaj hasło!' }],
          })(
            <Input
              // disabled={passwordDisabled}
              type="password"
              autoComplete="new-password"
              placeholder="Hasło"
            />,
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" style={{ width: '100%' }} onClick={this.handleSave}>Zapisz</Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(UserForm);

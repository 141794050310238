import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Form, Select, DatePicker, Button,
} from 'antd';
import { observable } from 'mobx';
import moment from 'moment';

import Base from 'components/Base';
import siteService from 'service/SiteService';

import './ReportForm.scss';

const { Option } = Select;
const FormItem = Form.Item;
const { MonthPicker, RangePicker } = DatePicker;

@inject('appStore')
@observer
class ReportForm extends Base {
  @observable isMonthRequred = false;

  @observable isRangeRequired = false;

  @observable users = [];

  componentDidMount() {
    this.loadProjects((projects) => {
      this.projects = projects.map((old) => {
        const project = Object.assign({}, old);
        project.key = `prid${project.ID}`;
        return project;
      });
    });
    this.loadUsers();
    this.selectPreopen();
    this.s('app').selectedItem = 'report';
  }

  selectPreopen() {
    const { preopen } = this.props;
    if (preopen === undefined) {
      return;
    }
    if (preopen === 'month') {
      this.isMonthRequred = true;
      this.props.form.setFieldsValue({
        time: 1,
      });
      if (this.props.month !== undefined && moment.isMoment(this.props.month)) {
        this.props.form.setFieldsValue({
          'time-month': this.props.month,
        });
      }
    }
    if (preopen === 'range') {
      this.isRangeRequired = true;
      this.props.form.setFieldsValue({
        time: 2,
      });
      if (this.props.month !== undefined && moment.isMoment(this.props.month)) {
        const { month } = this.props;
        this.props.form.setFieldsValue({
          'time-range': [month.startOf('month'), month.clone().endOf('month')],
        });
      }
    }
  }

  loadUsers = () => {
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .getUsers(this.props.type)
      .then(this.handleResponse)
      .then((son) => {
        this.s('app').loaded();
        this.users = son;
      })
      .catch(this.handleResponseError('Błąd podczas ładowania!'));
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(this.props.onSearchClick);
  }

  handleChange = (e) => {
    const value = Number(e);
    if (value === 0) {
      this.isMonthRequred = false;
      this.isRangeRequired = false;
      return;
    }
    this.isRangeRequired = (value % 2) === 0;
    this.isMonthRequred = (value % 2) === 1;
  }

  getRangeSelectDisplay() {
    if (this.props.allowTimespan === false) {
      return 'none';
    }
    return 'inline-block';
  }

  handleSearch = (value, option) => option.props.children
    .toLowerCase().indexOf(value.toLowerCase()) >= 0;

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="inline" onSubmit={this.handleSubmit} className="ReportForm">
        <FormItem>
          {getFieldDecorator('project', {
            initialValue: 0,
            rules: [{ required: true, message: 'Wybierz projekt!' }],
          })(
            <Select
              className="ReportForm__select"
              mode="multiple"
              filterOption={this.handleSearch}
              placeholder="Wybierz projekty"
            >
              <Option key="all" value={0}>Wszystkie projekty</Option>
              {this.projects.map(project => (
                <Option key={project.key} value={project.ID}>{project.Name}</Option>
              ))}
            </Select>,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('user', {
            initialValue: '0',
            rules: [{ required: true, message: 'Wybierz użytkownika!' }],
          })(
            <Select
              className="ReportForm__select"
              mode="multiple"
              placeholder="Wybierz użytkownika"
              filterOption={this.handleSearch}
            >
              <Option key="all" value="0">Wszyscy użytkownicy</Option>
              {this.users.map(user => (
                <Option key={user.ID} value={user.ID}>{user.Name}</Option>
              ))}
            </Select>,
          )}
        </FormItem>
        <FormItem style={{ display: this.getRangeSelectDisplay() }}>
          {getFieldDecorator('time', {
            initialValue: '0',
          })(
            <Select onChange={this.handleChange}>
              <Option value="0">Cały czas</Option>
              <Option value="1">Miesiąc</Option>
              <Option value="2">Zakres dat</Option>
            </Select>,
          )}
        </FormItem>
        <FormItem style={{ display: (this.isMonthRequred) ? 'inline-block' : 'none' }}>
          {getFieldDecorator('time-month', {
            rules: [{ required: this.isMonthRequred, message: 'Wybierz miesiąc!' }],
          })(
            <MonthPicker placeholder="Wybierz miesiąc" />,
          )}
        </FormItem>
        <FormItem style={{ display: (this.isRangeRequired) ? 'inline-block' : 'none' }}>
          {getFieldDecorator('time-range', {
            rules: [{ required: this.isRangeRequired, message: 'Wybierz zakres dat!' }],
          })(
            <RangePicker />,
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit">Pokaż</Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(ReportForm);

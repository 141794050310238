import React from 'react';
import {
  Table, Button, Divider, Popconfirm,
} from 'antd';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import Base from 'components/Base';
import siteService from 'service/SiteService';

@inject('appStore')
@observer
class ProjectTable extends Base {
  @observable roles = [];

  constructor(props) {
    super(props);
    this.setupColumns();
  }

  setupColumns() {
    this.columns = [
      {
        title: 'Nazwa',
        dataIndex: 'Name',
        key: 'name',
      },
      {
        title: 'Opis',
        dataIndex: 'Description',
        key: 'description',
      },
      {
        title: 'Aktywny',
        dataIndex: 'Disabled',
        key: 'enabled',
        render: v => (
          <span>
            {Number(v) === 1 ? 'Nie' : 'Tak'}
          </span>
        ),
      },
      {
        title: 'Widoczny',
        dataIndex: 'Hidden',
        key: 'hidden',
        render: v => (
          <span>
            {Number(v) === 1 ? 'Nie' : 'Tak'}
          </span>
        ),
      },
      {
        title: 'Akcje',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button
              onClick={this.handleEdit(record)}
            >
              Edytuj
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure delete this task?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.handleDelete(record)}
            >
              <Button
                type="danger"
              >
                Usuń
              </Button>
            </Popconfirm>
          </span>
        ),
      },
    ];
  }


  handleEdit = row => () => {
    this.props.onAction(row.ID, 'edit');
  }

  handleDelete = row => () => {
    this.props.onAction(row.ID, 'delete');
  }

  loadProjectsFull = () => {
    // this.loadProjects((projects) => {
    //   this.projects = projects.map((row) => {
    //     const data = Object.assign(row, { key: row.ID });
    //     return data;
    //   });
    // });
    siteService
      .api(this.s('app').token)
      .getProjectsAdmin()
      .then(this.handleResponse)
      .then((projects) => {
        this.projects = projects.map((row) => {
          const data = Object.assign(row, { key: row.ID });
          return data;
        });
      })
      .catch(this.handleResponseError('Nie można załadować projektów!'));
  }

  componentDidMount() {
    this.loadProjectsFull();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateCount === this.props.updateCount) {
      return;
    }
    this.loadProjectsFull();
  }

  setRowProps = record => ({ rowKey: record.ID })

  handleClick = (e) => {
    e.preventDefault();
    this.props.onAction(
      e.target.getAttribute('data-id'),
      e.target.getAttribute('data-action'),
    );
  }

  render() {
    return (
      <Table
        dataSource={this.projects}
        columns={this.columns}
        pagination={false}
        onRow={this.setRowProps}
      />
    );
  }
}

export default ProjectTable;

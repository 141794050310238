import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { message } from 'antd';
import ProjectDataSourceItem from 'models/ProjectDataSourceItem';

import { clearSession } from 'utils/storage';
import siteService from 'service/SiteService';

@observer
class Base extends React.Component {
  @observable projects = [];

  loadProjects =(callback, parseCallback, enabledOnly) => {
    this.s('app').loading();
    siteService.api(this.s('app').token)
      .getProjects(enabledOnly === true)
      .then(this.handleResponse)
      .then((projects) => {
        this.s('app').loaded();
        if (typeof parseCallback !== 'function') {
          this.projects = projects
            .map(project => new ProjectDataSourceItem(project.ID, project.Name));
        } else {
          parseCallback(projects);
        }
        if (typeof callback === 'function') {
          callback(projects);
        }
      })
      .catch(this.handleResponseError('Błąd ładowania danych'));
  }

  s = (name) => {
    const storeName = `${name}Store`;
    if (!(storeName in this.props)) {
      throw new Error(`Store ${storeName} not found!`);
    }
    // eslint-disable-next-line
    return this.props[storeName];
  }

  handleResponseError = msg => (err) => {
    this.s('app').loaded();
    // eslint-disable-next-line no-console
    console.warn(err);
    message.error(msg);
  }

  handleResponse = async (resp) => {
    const son = await resp.json();
    if ((resp.status === 400 && son.message !== undefined) || resp.status === 401) {
      clearSession();
      this.s('app').loaded();
      this.logout();
      return Promise.reject(new Error('jwt session expired!'));
    }
    if (resp.status === 418) {
      son.ValidationError = true;
      return Promise.resolve(son);
    }
    if (resp.status !== 200) {
      return Promise.reject(son.Message);
    }
    return Promise.resolve(son);
  }

  logout() {
    clearSession();
    this.s('app').token = null;
    const { history } = this.props;
    if (history) {
      history.push('/login');
    } else {
      // eslint-disable-next-line
        console.warn('You need to use @withRouter from react-router for this component!');
    }
  }
}

export default Base;

import React from 'react';
import { observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import {
  Table, List, Row, Col, Button,
} from 'antd';
import moment from 'moment';
import fileSaver from 'file-saver';

import Base from 'components/Base';
import siteService from 'service/SiteService';
import { formatMinutes, prettyMinutes } from 'utils/moment';

import './TimesheetReportTable.scss';

const { Item } = List;

@inject('appStore')
@observer
class TimesheetReportTable extends Base {
  @observable data = [];

  @observable tableData = [];

  @observable selectedUser = '';

  @observable loading = false;

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Dzień',
        dataIndex: 'Day',
        key: 'day',
        render: v => (
          <span>
            {moment(v).format('YYYY-MM-DD')}
          </span>
        ),
      },
      {
        title: 'Start',
        dataIndex: 'StartTime',
        key: 'start',
        render: v => (
          <span>
            {formatMinutes(v)}
          </span>
        ),
      },
      {
        title: 'Koniec',
        dataIndex: 'EndTime',
        key: 'end',
        render: v => (
          <span>
            {formatMinutes(v)}
          </span>
        ),
      },
      {
        title: 'Projekty',
        dataIndex: 'Projects',
        key: 'projects',
        render: v => (
          <span>
            {v.join(', ')}
          </span>
        ),
      },
      {
        title: 'Wyjścia',
        dataIndex: 'Entries',
        key: 'entries',
        render: v => (
          <ul>
            {v.map(item => (
              <li key={item.StartTime}>
                {formatMinutes(item.StartTime)}
                {' - '}
                {formatMinutes(item.EndTime)}
              </li>
            )) }
          </ul>
        ),
      },
      {
        title: 'Czas pracy',
        dataIndex: 'WorkingTime',
        key: 'work_time',
        render: v => (
          <span>
            {prettyMinutes(v)}
          </span>
        ),
      },
    ];
  }

  getReport = () => {
    this.s('app').loading();
    siteService.api(this.s('app').token)
      .getTimesheetReport(JSON.stringify(this.props.data))
      .then(this.handleResponse)
      .then((data) => {
        this.data = data;
        if (data.Users !== undefined && data.Users.length > 0) {
          this.reloadTable(data.Users[0].Uid);
        }
        this.s('app').loaded();
      })
      .catch(this.handleResponseError('Nie można załadować raportu'));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateCount === this.props.updateCount) {
      return;
    }
    this.selectedUser = '';
    this.tableData.replace([]);
    this.getReport();
  }

  reloadTable = (uid) => {
    this.loading = true;
    this.selectedUser = uid;
    if (uid in this.data.Data) {
      this.tableData.replace(toJS(this.data.Data[uid].Data));
    } else {
      this.tableData.replace([]);
    }
    this.loading = false;
  }

  handleUserClick = uid => (e) => {
    e.preventDefault();
    this.reloadTable(uid);
  }

  renderFooter = () => {
    let result = '';
    if (this.data.Data === undefined || this.data.Data[this.selectedUser] === undefined) {
      result = 'Suma: 0';
    } else {
      result = `Suma: ${prettyMinutes(this.data.Data[this.selectedUser].TotalTime)}`;
    }
    return (
      <div className="footerContainer">
        <div className="footerText">{result}</div>
      </div>
    );
  }

  handleDownloadButton = () => {
    this.s('app').loading();
    siteService.api(this.s('app').token)
      .getDownloadToken('timesheet')
      .then(this.handleResponse)
      .then(((dToken) => {
        const data = encodeURIComponent(JSON.stringify(this.props.data));
        return siteService.downloadTimesheet(data, dToken);
      }))
      .then(async (r) => {
        const data = await r.blob();
        fileSaver.saveAs(data, `raport-we-wy-${moment().format('YYYY-MM-DD_HH-mm')}.xlsx`);
        this.s('app').loaded();
      })
      .catch(this.handleResponseError('Nie mozna pobrać!'));
  }

  render() {
    return (
      <Row className="TimesheetReportTable">
        <Col md={5}>
          {this.data.Users ? <Button onClick={this.handleDownloadButton}>Pobierz</Button> : <></> }
          <List
            key={this.selectedUser}
            dataSource={this.data.Users}
            pagination={false}
            header="Wybierz użytkownika"
            locale={{
              emptyText: 'Brak użytkowników',
            }}
            renderItem={item => (
              <Item
                key={item.Uid}
                className={item.Uid === this.selectedUser ? 'item-selected' : ''}
                onClick={this.handleUserClick(item.Uid)}
              >
                {item.Name}
              </Item>
            )}
          />
        </Col>
        <Col md={1} />
        <Col md={18}>
          { this.loading ? 'Loading...' : (
            <Table
              key={`table_${this.selectedUser}`}
              dataSource={this.tableData}
              columns={this.columns}
              pagination={false}
              rowKey={record => record.Day}
              footer={this.renderFooter}
            />
          )}
        </Col>
      </Row>
    );
  }
}

export default TimesheetReportTable;

import React from 'react';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import {
  Layout, ConfigProvider,
} from 'antd';
import plPL from 'antd/es/locale-provider/pl_PL';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Base from 'components/Base';

import Login from 'pages/Login/Login.page';
import Home from 'pages/Home/Home.page';
import Report from 'pages/Report/Report.page';
import Users from 'pages/Users/Users.page';
import Timesheet from 'pages/Timesheet/Timesheet.page';
import TimesheetReport from 'pages/TimesheetReport/TimesheetReport.page';
import Projects from 'pages/Projects/Projects.page';
import AddLog from 'pages/AddLog/AddLog.page';
import System from 'pages/System/System.page';
import LogBrowser from 'pages/LogBrowser/LogBrowser.page';

import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import AppHeader from 'components/AppHeader/AppHeader';

import './App.scss';

const { Content } = Layout;

@inject('appStore')
@observer
class App extends Base {
  @computed get isLogged() {
    return this.s('app').token !== null;
  }

  @computed get isLoggedAdmin() {
    return this.isLogged && this.s('app').superUser;
  }

  render() {
    return (
      <div className="App">
        <ConfigProvider locale={plPL}>
          <Router>
            <Layout>
              <AppHeader />
              <Content style={{ padding: '20px 50px' }}>
                <ProtectedRoute exact path="/" component={Home} loggedIn={this.isLogged} />
                <ProtectedRoute path="/report" component={Report} loggedIn={this.isLogged} />
                <ProtectedRoute path="/log_browser" component={LogBrowser} loggedIn={this.isLogged} />
                <ProtectedRoute path="/add_log" component={AddLog} loggedIn={this.isLogged} />
                <ProtectedRoute path="/timesheet" exact component={Timesheet} loggedIn={this.isLogged} />
                <ProtectedRoute path="/timesheet/:day" component={Timesheet} loggedIn={this.isLogged} />
                <ProtectedRoute path="/timesheet_report" component={TimesheetReport} loggedIn={this.isLogged} />
                <ProtectedRoute path="/users" component={Users} loggedIn={this.isLoggedAdmin} />
                <ProtectedRoute path="/projects" component={Projects} loggedIn={this.isLoggedAdmin} />
                <ProtectedRoute path="/system" component={System} loggedIn={this.isLoggedAdmin} />
                <Route path="/login" component={Login} />
              </Content>
            </Layout>
          </Router>
        </ConfigProvider>
      </div>
    );
  }
}

export default (App);

import { observable } from 'mobx';


export class UserFormStore {
  @observable opened = false;

  @observable userId = 0;

  @observable action = '';
}


const userFormStore = new UserFormStore();

export default userFormStore;

import React from 'react';
import moment from 'moment';
import 'moment/locale/pl';
import { withRouter } from 'react-router-dom';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import {
  Form, Input, InputNumber, Button,
  DatePicker, AutoComplete, message,
} from 'antd';

import Base from 'components/Base';
import siteService from 'service/SiteService';
import PickUpTime from 'components/PickUpTime/PickUpTime';

const FormItem = Form.Item;
@withRouter
@inject('appStore')
@observer
class LogForm extends Base {
  @observable projects = [];

  @observable pickupOpened = false;

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (typeof this.props.onFormSubmit === 'function') {
        this.props.onFormSubmit(e, err, values);
        return;
      }
      if (!err) {
        const data = Object.assign(values, {
          Uid: this.s('app').userId,
        });
        data.Pid = Number(data.Pid);
        data.Day = data.Day.format();
        this.s('app').loading();
        siteService
          .api(this.s('app').token)
          .createLog(JSON.stringify(data))
          .then(this.handleResponse)
          .then(() => {
            message.success('Wpis został poprawnie dodany!');
            this.s('app').loaded();
            this.s('app').update();
          })
          .catch(this.handleResponseError('Błąd podczas dodawania wpisu!'));
      }
    });
  }

  handlePicker = (what) => {
    if (what === undefined) {
      this.pickupOpened = true;
    }
    this.pickupOpened = what;
  }

  handlePickerValue = (Duration) => {
    const { form } = this.props;
    this.pickupOpened = false;
    form.setFieldsValue({
      Duration,
    });
  }

  componentDidMount() {
    this.props.form.setFieldsValue({
      Day: moment(),
    });
    this.loadProjects(0, 0, true);
  }

  handleSearch = (value, option) => option.props.children
    .toLowerCase().indexOf(value.toLowerCase()) >= 0;

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="log-form">
        <FormItem>
          {getFieldDecorator('Day', {
            rules: [{ required: true, message: 'Podaj datę!' }],
          })(
            <DatePicker
              style={{ width: '100%' }}
              format="YYYY-MM-DD"
              placeholder="Dzień"
            />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('Pid', {
            rules: [{ required: true, message: 'Wybierz projekt!' }],
          })(
            <AutoComplete
              dataSource={this.projects}
              style={{ width: '100%' }}
              filterOption={this.handleSearch}
              placeholder="Nazwa projektu"
            />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('Duration', {
            rules: [{ required: true, message: 'Podaj czas trwania!' }],
          })(
            <InputNumber
              min={1}
              max={24 * 60}
              step={1}
              style={{ width: '90%' }}
              placeholder="Czas w minutach"
            />,
          )}
          <Button icon="tool" type="dashed" style={{ width: '10%' }} onClick={this.handlePicker} />
        </FormItem>
        <FormItem>
          {getFieldDecorator('Comment', {
            rules: [{ required: false, message: 'Dodaj komentarz' }],
          })(
            <Input
              style={{ width: '100%' }}
              placeholder="Komentarz"
            />,
          )}
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            style={{ width: '100%' }}
            htmlType="submit"
            className="login-form-button"
          >
            Zapisz
          </Button>
        </FormItem>
        <PickUpTime
          enabled={!!this.pickupOpened}
          onCancel={this.handlePicker}
          onSubmit={this.handlePickerValue}
        />
      </Form>
    );
  }

  render() {
    return (
      <div>
        { this.renderForm() }
      </div>);
  }
}

export default Form.create()(LogForm);

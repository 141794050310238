import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Modal, Button,
} from 'antd';
// import { observable } from 'mobx';

import Base from 'components/Base';
import LogEditForm from 'components/LogEditForm/LogEditForm';

@inject('appStore')
@observer
class LogModal extends Base {
  handleClose = () => {
    this.s('app').logModalOpened = false;
  }

  handleForm = () => {
    const { onSubmit } = this.props;
    this.handleClose();
    if (typeof onSubmit === 'function') {
      onSubmit();
    }
  }

  render() {
    const { logId } = this.props;
    return (
      <Modal
        title="Wpis"
        visible={this.s('app').logModalOpened}
        onCancel={this.handleClose}
        maskClosable={false}
        destroyOnClose
        footer={[
          <Button key="back" onClick={this.handleClose}>Porzuć zmiany i zamknij</Button>,
        ]}
      >
        <LogEditForm
          logId={logId}
          onFormSubmit={this.handleForm}
        />
      </Modal>
    );
  }
}

export default LogModal;

import React from 'react';
import { observer, inject } from 'mobx-react';
import {
  Row, Col, Collapse,
} from 'antd';

import Base from 'components/Base';
import LogForm from 'components/LogForm/LogForm';
import TimerForm from 'components/TimerForm/TimerForm';
import LastEntries from 'components/LastEntries/LastEntries';
import History from 'components/History/History';
import { setOpenedForm } from 'utils/storage';

const { Panel } = Collapse;

@inject('appStore')
@observer
class AddLog extends Base {
  componentDidMount() {
    this.s('app').selectedItem = 'add_log';
  }

  handleChange = (id) => {
    this.s('app').homepageOpenedForm = id;
    setOpenedForm(id);
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={8} lg={8}>
            <Collapse defaultActiveKey={[String(this.s('app').homepageOpenedForm)]} accordion onChange={this.handleChange}>
              <Panel header="Formularz" key="1">
                <LogForm />
              </Panel>
              <Panel header="Minutnik" key="2">
                <TimerForm />
              </Panel>
            </Collapse>
          </Col>
          <Col xs={0} md={1} />
          <Col md={6} lg={7}>
            <h2>Ostatnie wpisy</h2>
            <LastEntries updateCount={this.s('app').updateCount} />
          </Col>
          <Col xs={0} md={1} />
          <Col md={6} lg={7}>
            <h2>Zalogowany czas</h2>
            <History
              updateCount={this.s('app').updateCount}
              showButton={false}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddLog;

/* global window */
import React from 'react';
import 'moment/locale/pl';
import { withRouter } from 'react-router-dom';
import { observable } from 'mobx';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import {
  Form, Input, Button, AutoComplete, Row, Col, Icon, Popconfirm, message,
} from 'antd';

import Base from 'components/Base';
import History from 'components/History/History';
import siteService from 'service/SiteService';
import { clearTimerForm } from 'utils/storage';

import './TimerForm.scss';

const FormItem = Form.Item;
const ButtonGroup = Button.Group;

@withRouter
@inject('appStore', 'timerStore')
@observer
class TimerForm extends Base {
  @observable projects = [];

  @observable elapsed = 0;

  getElapsed = () => {
    if (!this.s('timer').started) {
      return 0;
    }
    const d = moment(this.s('timer').startTime);
    return moment().diff(d, 'minutes');
  }

  setElapsed = () => {
    if (!this.s('timer').started) {
      this.elapsed = 0;
      return;
    }
    if (!this.s('timer').running) {
      this.elapsed = this.s('timer').elapsed;
      return;
    }
    this.elapsed = this.s('timer').elapsed + this.getElapsed();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = Object.assign(values, {
          Uid: this.s('app').userId,
        });
        if (this.s('timer').started) {
          return;
        }
        data.Pid = Number(data.Pid);
        this.s('timer').pid = data.Pid;
        const now = moment().format();
        this.s('timer').day = now;
        this.s('timer').startTime = now;
        this.s('timer').started = true;
        this.s('timer').running = true;
        window.localStorage.setItem('timerForm', this.s('timer').serialize());
      }
    });
  }

  handlePause = (e) => {
    e.preventDefault();
    this.pause();
  }

  pause = () => {
    this.s('timer').running = !this.s('timer').running;
    if (this.s('timer').running) {
      this.s('timer').startTime = moment().format();
      window.localStorage.setItem('timerForm', this.s('timer').serialize());
      return;
    }
    this.s('timer').elapsed = this.s('timer').elapsed + this.getElapsed();
    window.localStorage.setItem('timerForm', this.s('timer').serialize());
  }

  handleSave = () => {
    if (this.s('timer').running) {
      this.pause();
    }
    if (this.s('timer').elapsed <= 0) {
      message.warn('Zalogowany czas musi być wiekszy niż 0.');
      return;
    }
    const data = {
      Pid: this.s('timer').pid,
      Day: this.s('timer').day,
      Duration: this.s('timer').elapsed,
      Uid: this.s('app').userId,
    };
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .createLog(JSON.stringify(data))
      .then(this.handleResponse)
      .then(() => {
        this.s('app').loaded();
        message.success('Dane zostały zapisane');
        this.handleClear();
        this.s('app').update();
      })
      .catch(this.handleResponseError('Nie udało się zapisać'));
  }

  handleClear = () => {
    this.s('timer').clear();
    this.elapsed = 0;
    clearTimerForm();
  }

  handleSearch = (value, option) => option.props.children
    .toLowerCase().indexOf(value.toLowerCase()) >= 0;

  componentDidMount() {
    this.setElapsed();
    this.loadProjects(0, 0, true);
    this.timer = window.setInterval(this.setElapsed, 1000);
    if (this.s('timer').pid === 0) {
      return;
    }
    this.props.form.setFieldsValue({
      Pid: String(this.s('timer').pid),
    });
  }

  componentWillUnmount() {
    window.clearInterval(this.timer);
    this.timer = null;
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="timerForm">
        <Row>
          <Col span={5}>
            <Icon className="iconSpinSlow timerIcon" spin={this.s('timer').running} type="hourglass" theme="outlined" />
          </Col>
          <Col span={19}>
            <FormItem>
              {getFieldDecorator('Pid', {
                rules: [{ required: true, message: 'Wybierz projekt!' }],
              })(
                <AutoComplete
                  dataSource={this.projects}
                  style={{ width: '100%' }}
                  disabled={this.s('timer').started}
                  filterOption={this.handleSearch}
                  placeholder="Nazwa projektu"
                />,
              )}
            </FormItem>
            <FormItem>
              <Input
                style={{ width: '100%' }}
                readOnly
                value={History.parseTime(this.elapsed)}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Row type="flex" justify="center" align="middle">
            <ButtonGroup>
              <Button
                type="primary"
                size="large"
                disabled={this.s('timer').started}
                htmlType="submit"
              >
                <Icon type="caret-right" theme="outlined" />
              </Button>
              <Button
                type="primary"
                size="large"
                disabled={!this.s('timer').started}
                htmlType="button"
                onClick={this.handlePause}
              >
                {this.s('timer').running ? <Icon type="pause" theme="outlined" /> : <Icon type="pause-circle" theme="outlined" />}
              </Button>
              <Popconfirm
                title="Co chcesz zrobić?"
                okText="Zapisz"
                cancelText="Anuluj"
                onConfirm={this.handleSave}
              >
                <Button
                  type="primary"
                  size="large"
                  disabled={!this.s('timer').started}
                >
                  <Icon type="save" theme="outlined" />
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Czy jesteś pewnie, że chcesz wyczyścić licznik?"
                okText="Tak"
                cancelText="Nie"
                onConfirm={this.handleClear}
              >
                <Button
                  disabled={!this.s('timer').started}
                  size="large"
                  type="danger"
                >
                  <Icon type="stop" theme="outlined" />
                </Button>
              </Popconfirm>
            </ButtonGroup>
          </Row>
        </Row>
      </Form>
    );
  }

  render() {
    return (
      <div>
        { this.renderForm() }
      </div>);
  }
}

export default Form.create()(TimerForm);

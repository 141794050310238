import React from 'react';
import {
  Modal, Button,
} from 'antd';
import { inject, observer } from 'mobx-react';

import Base from 'components/Base';
import ProjectForm from 'pages/Projects/components/ProjectForm/ProjectForm';

@inject('appStore', 'projectFormStore')
@observer
class ProjectModal extends Base {
  handleCancel = () => {
    this.s('projectForm').opened = false;
  }

  render() {
    return (
      <Modal
        title="Projekt"
        visible={this.s('projectForm').opened}
        onCancel={this.handleCancel}
        maskClosable={false}
        destroyOnClose
        footer={[
          <Button key="back" onClick={this.handleCancel}>Porzuć zmiany i zamknij</Button>,
        ]}
      >
        <ProjectForm />
      </Modal>
    );
  }
}

export default ProjectModal;

import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import moment from 'moment';
import { Divider } from 'antd';

import Base from 'components/Base';
import siteService from 'service/SiteService';
import History from 'components/History/History';

@inject('appStore', 'timesheetStore')
@observer
class LogsForDay extends Base {
  @observable logs = [];

  @observable summary = {};

  @observable sum = 0;

  componentDidMount() {
    this.loadProjects(this.loadLogs);
  }

  loadLogs = () => {
    const { Day } = this.s('timesheet');
    this.loadLogsDay(Day);
  }

  loadLogsDay = (Day) => {
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .getLogDay(Day)
      .then(this.handleResponse)
      .then((son) => {
        this.sum = 0;
        const data = son.map(item => ({
          ID: item.ID,
          Pid: item.Pid,
          ProjectName: this.getProject(item.Pid),
          Duration: item.Duration,
        }));
        this.logs.replace(data);
        this.summary = data.reduce((obj, item) => {
          const result = { ...obj };
          if (!(item.Pid in result)) {
            result[item.Pid] = {
              ProjectName: item.ProjectName,
              Duration: 0,
            };
          }
          result[item.Pid].Duration += item.Duration;
          this.sum += item.Duration;
          return result;
        }, {});
        this.s('app').loaded();
      })
      .catch(this.handleResponseError('Błąd ładowania danych!'));
  }

  getProject = (id) => {
    const project = this.projects.find(el => Number(el.value) === Number(id));
    if (project === undefined) {
      return 'Brak nazwy';
    }
    return project.text;
  }

  dispose = reaction(
    () => this.s('timesheet').Day,
    (Day) => {
      this.loadLogsDay(Day);
    },
  );

  componentWillUnmount() {
    this.dispose();
  }

  render() {
    const { Day } = this.s('timesheet');
    const NiceDate = moment(Day).format('DD-MM-YYYY');

    return (
      <div>
        <h2>
          Wpisy z dnia
          {' '}
          {NiceDate}
        </h2>
        {this.logs.length <= 0 ? (
          <React.Fragment>
            Brak wpisów.
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h3>Podsumowanie</h3>
            <ul>
              {Object.keys(this.summary).map(Pid => (
                <li key={`pid-${Pid}`}>
                  <strong>{this.summary[Pid].ProjectName}</strong>
                  {' - '}
                  {History.parseTime(this.summary[Pid].Duration)}
                </li>
              ))}
            </ul>
            <div>
              <strong>SUMA: </strong>
              {History.parseTime(this.sum)}
            </div>
            <Divider />
            <h3>Szczegóły</h3>
            <ul>
              {this.logs.map(item => (
                <li key={item.ID}>
                  {History.parseTime(item.Duration)}
                  {' - '}
                  <strong>{item.ProjectName}</strong>
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default LogsForDay;

import React from 'react';
import {
  List,
  Button,
  Divider,
  Popconfirm,
} from 'antd';
import { observer, inject } from 'mobx-react';
import Base from 'components/Base';

import { formatMinutes } from 'utils/moment';
import siteService from 'service/SiteService';

const { Item } = List;

@inject('appStore', 'timesheetStore')
@observer
class InOutListItem extends Base {
  handleDelete = () => {
    const entry = {
      Id: this.props.item.dbId,
      DayId: this.s('timesheet').DayId,
    };
    this.s('app').loading();
    siteService.api(this.s('app').token)
      .deleteTimesheetEntry(JSON.stringify(entry))
      .then(this.handleResponse)
      .then(() => {
        this.s('timesheet').deleteInOut(this.props.item.dbId);
        this.s('app').loaded();
      })
      .catch(this.handleResponseError('Nie można usunąć dnia!'));
  }

  render() {
    const { item } = this.props;
    const startTime = formatMinutes(item.start);
    const endTime = formatMinutes(item.end);
    return (
      <Item>
        {startTime}
      &nbsp;
      -
      &nbsp;
        {endTime}
        <Divider type="vertical" />
        <Popconfirm
          title="Jesteś pewien, że chcesz usunąć ten wpis?"
          onConfirm={this.handleDelete}
          okText="Tak"
          cancelText="Nie"
        >
          <Button type="primary" shape="circle" icon="delete" size="small" />
        </Popconfirm>
      </Item>
    );
  }
}

export default InOutListItem;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function ProtectedRoute(props) {
  const { component: Component, ...newProps } = props;

  return (
    <Route
      {...newProps}
      render={compProps => (
        props.loggedIn
          ? <Component {...compProps} />
          : <Redirect to="/login" />
      )}
    />
  );
}
export default ProtectedRoute;

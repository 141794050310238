import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Form, DatePicker, Select, Divider, Button, message,
} from 'antd';
import moment from 'moment';
import Base from 'components/Base';

import siteService from 'service/SiteService';
import ConfirmTimePicker from 'components/ConfirmTimePicker/ConfirmTimePicker';
import {
  getHour, getMinutes, getMinutesFromMidnight, checkInRange,
} from 'utils/moment';

import './TimesheetDayForm.scss';

const FormItem = Form.Item;
const { Option } = Select;

@inject('appStore', 'timesheetStore')
@observer
class TimesheetDayForm extends Base {
  componentDidMount() {
    this.loadProjects();
    this.loadDay();
  }

  loadDay = () => {
    if (this.s('timesheet').Day === '') {
      this.s('timesheet').reset();
    }
    this.s('app').loading();
    siteService.api(this.s('app').token)
      .getTimesheet(this.s('timesheet').Day)
      .then(this.handleResponse)
      .then((json) => {
        this.s('timesheet').loadFromJson(json);
        this.fillForm(json);
        this.s('app').loaded();
      })
      .catch(this.handleResponseError('Nie można pobrać dnia!'));
  }

  fillForm = (data) => {
    const Day = moment(data.Day);
    const TimeStart = Day.clone().hour(getHour(data.StartTime)).minutes(getMinutes(data.StartTime));
    const TimeEnd = Day.clone().hour(getHour(data.EndTime)).minutes(getMinutes(data.EndTime));
    let Projects = [];
    if (data.Projects !== null) {
      Projects = data.Projects.map(project => project.ID);
    }
    this.props.form.setFieldsValue({
      Day,
      TimeStart,
      TimeEnd,
      Projects,
    });
  }

  validateTimeEnd = (rule, value, cb) => {
    const startTime = this.s('timesheet').TimeStart;
    const EndTime = getMinutesFromMidnight(value);
    if (EndTime === 0 && startTime === 0 && this.s('timesheet').InOut.length <= 0) {
      return cb();
    }
    let isValid = true;
    this.s('timesheet').InOut.forEach((element) => {
      if (
        !checkInRange(element.start, startTime, EndTime)
        || !checkInRange(element.end, startTime, EndTime)) {
        isValid = false;
      }
    });
    if (!isValid) {
      message.warn('Wprowadzony czas nie pokrywa się z wyjściami.');
      return cb(true);
    }

    if (EndTime >= this.s('timesheet').TimeStart) {
      return cb();
    }
    return cb(true);
  }

  validateTimeStart = (rule, value, cb) => {
    let isValid = true;
    const startTime = getMinutesFromMidnight(value);
    const EndTime = this.s('timesheet').TimeEnd;
    this.s('timesheet').InOut.forEach((element) => {
      if (
        !checkInRange(element.start, startTime, EndTime)
        || !checkInRange(element.end, startTime, EndTime)) {
        isValid = false;
      }
    });
    if (!isValid) {
      message.warn('Wprowadzony czas nie pokrywa się z wyjściami.');
      return cb(true);
    }
    return cb();
  }

  chandleChange = name => (e) => {
    this.s('timesheet').setValue(name, e);
    this.s('timesheet').dayChanged = true;
    if (name === 'Day') {
      this.loadDay();
      this.s('timesheet').dayChanged = false;
    }
  }

  handleSave = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err) => {
      if (err !== null) {
        return;
      }
      const data = this.s('timesheet').getObject();
      this.s('app').loading();
      siteService.api(this.s('app').token)
        .saveTimesheet(JSON.stringify(data))
        .then(this.handleResponse)
        .then((son) => {
          if (son.ValidationError) {
            message.error('Błąd zapisywania, sprawdź poprawność danych!');
          } else {
            message.success('Dane zapisane!');
          }
          this.s('app').loaded();
          this.s('timesheet').dayChanged = false;
        })
        .catch(this.handleResponseError('Błąd podczas zapisywania danych!'));
    });
  }

  isSaveButtonDisabled() {
    return !(this.s('timesheet').dayChanged && (this.s('timesheet').TimeStart < this.s('timesheet').TimeEnd));
  }

  handleSearch = (value, option) => option.props.children
    .toLowerCase().indexOf(value.toLowerCase()) >= 0;

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className="TimesheetDayForm" onSubmit={this.handleSave}>
        <p>Zmiana daty spowoduje załadowania danych z innego dnia.</p>
        <FormItem>
          {getFieldDecorator('Day', {
          })(
            <DatePicker placeholder="Wybierz dzień" onChange={this.chandleChange('Day')} />,
          )}
        </FormItem>
        <Divider />
        <FormItem>
          {getFieldDecorator('TimeStart', {
            rules: [
              {
                message: 'Niepoprawny czas rozpoczęcia.',
                validator: this.validateTimeStart,
              },
            ],
          })(
            <ConfirmTimePicker format="HH:mm" placeholder="Godzina rozpoczęcia" onChange={this.chandleChange('TimeStart')} />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('TimeEnd', {
            rules: [
              {
                message: 'Niepoprawny czas zakonczenia.',
                validator: this.validateTimeEnd,
              },
            ],
          })(
            <ConfirmTimePicker format="HH:mm" placeholder="Godzina zakończenia" onChange={this.chandleChange('TimeEnd')} />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('Projects', {
          })(
            <Select
              mode="multiple"
              placeholder="Projekty"
              filterOption={this.handleSearch}
              onChange={this.chandleChange('Projects')}
            >
              {this.projects.map(data => (
                <Option
                  key={data.key}
                  value={data.value}
                >
                  {data.text}
                </Option>))}
            </Select>,
          )}
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            disabled={this.isSaveButtonDisabled()}
          >
          Zapisz
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(TimesheetDayForm);

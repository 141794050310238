
/* global fetch window */


let serviceUrl = 'https://api.time.ciap.com.pl/';
if (/localhost/i.test(window.location.host)) {
  serviceUrl = 'http://localhost:3030/';
}
// 192.168.43.72
if (/^[0-9.]+/i.test(window.location.host)) {
  serviceUrl = `http://${window.location.hostname}:3030/`;
}
const headerConfig = {
  // 'Content-Type': 'application/json; charset=utf-8',
  Accept: 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded',
};

export function getServiceUrl() {
  return serviceUrl;
}

export function makeRequest(url, method, headers, data, qs) {
  const uri = new URL(`${serviceUrl}${url}`);
  let settings = {
    method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers,
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    // body: data, // body data type must match "Content-Type" header
  };
  if (data !== undefined) {
    settings = Object.assign(settings, {
      body: data,
    });
  }
  if (qs !== undefined) {
    const keys = Object.keys(qs);
    keys.forEach(key => uri.searchParams.append(key, qs[key]));
  }
  return fetch(uri, settings);
}

export class ApiService {
  constructor(apiKey) {
    this.headers = Object.assign({
      Authorization: `Bearer ${apiKey}`,
    }, headerConfig);
  }

  getProjects(enabledOnly) {
    let q;
    if (enabledOnly === true) {
      q = {
        enabledOnly,
      };
    }
    return makeRequest('api/project', 'GET', this.headers, undefined, q);
  }

  getProjectsAdmin() {
    return makeRequest('api/admin/project', 'GET', this.headers);
  }

  getDownloadToken(resource) {
    return makeRequest('api/download/token', 'GET', this.headers, undefined, {
      resource,
    });
  }

  getHistory() {
    return makeRequest('api/history', 'GET', this.headers);
  }

  deleteLog(id) {
    return makeRequest('api/log', 'DELETE', this.headers, undefined, {
      id,
    });
  }

  updateLog(data) {
    return makeRequest('api/log', 'PUT', this.headers, data);
  }

  getTimesheet(day) {
    return makeRequest('api/timesheet', 'GET', this.headers, undefined, {
      day,
    });
  }

  saveTimesheet(data) {
    return makeRequest('api/timesheet', 'PUT', this.headers, data);
  }

  getTimesheetLog(day) {
    return makeRequest('api/timesheet/log', 'GET', this.headers, undefined, {
      day,
    });
  }

  getLogBrowser(page, limit, data) {
    return makeRequest('api/log/browser', 'POST', this.headers, data, {
      limit,
      page,
    });
  }

  getTimesheetReport(data) {
    return makeRequest('api/timesheet/report', 'POST', this.headers, data);
  }

  saveTimesheetEntry(data) {
    return makeRequest('api/timesheet/entry', 'POST', this.headers, data);
  }

  deleteTimesheetEntry(data) {
    return makeRequest('api/timesheet/entry', 'DELETE', this.headers, data);
  }

  getLogById(id) {
    return makeRequest('api/log', 'GET', this.headers, undefined, {
      id,
    });
  }

  getReport(data) {
    return makeRequest('api/report', 'POST', this.headers, data);
  }

  getLogLimit(limit) {
    return makeRequest('api/log', 'GET', this.headers, undefined, {
      limit,
    });
  }

  getLogDay(day) {
    return makeRequest('api/log', 'GET', this.headers, undefined, {
      day,
    });
  }

  getUsers(ctx) {
    return makeRequest('api/user', 'GET', this.headers, undefined, {
      ctx,
    });
  }

  getUser(id) {
    return makeRequest('api/admin/user', 'GET', this.headers, undefined, {
      id,
    });
  }

  createProject(data) {
    return makeRequest('api/admin/project', 'POST', this.headers, data);
  }

  updateProject(data) {
    return makeRequest('api/admin/project', 'PUT', this.headers, data);
  }

  getProject(id) {
    return makeRequest('api/admin/project', 'GET', this.headers, undefined, {
      id,
    });
  }

  getSystemIncorrectFix() {
    return makeRequest('api/admin/system', 'GET', this.headers, undefined, {
      action: 'fix_timesheet',
    });
  }


  getSystemIncorrect() {
    return makeRequest('api/admin/system', 'GET', this.headers, undefined, {
      action: 'scan_timesheet',
    });
  }

  getSystemStats() {
    return makeRequest('api/admin/system', 'GET', this.headers, undefined);
  }

  createUser(data) {
    return makeRequest('api/admin/user', 'POST', this.headers, data);
  }

  updateUser(data) {
    return makeRequest('api/admin/user', 'PUT', this.headers, data);
  }

  deleteProject(id) {
    return makeRequest('api/admin/project', 'DELETE', this.headers, undefined, {
      id,
    });
  }

  deleteUser(id) {
    return makeRequest('api/admin/user', 'DELETE', this.headers, undefined, {
      id,
    });
  }

  getRoles() {
    return makeRequest('api/admin/role', 'GET', this.headers);
  }

  createLog(data) {
    return makeRequest('api/log', 'POST', this.headers, data);
  }
}

export class SiteService {
  static getURLSearchParams(data) {
    return Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&')
      .toString();
  }

  constructor() {
    this.headers = headerConfig;
  }

  api(apiKey) {
    this.apiKey = apiKey;
    return new ApiService(apiKey);
  }

  login(username, password) {
    const formData = SiteService.getURLSearchParams({
      username,
      password,
    });
    return makeRequest('login', 'POST', this.headers, formData);
  }

  downloadTimesheet(data, token) {
    return makeRequest('download/timesheet', 'GET', this.headers, undefined, {
      data,
      token,
    });
  }

  downloadLog(data, token) {
    return makeRequest('download/log', 'GET', this.headers, undefined, {
      data,
      token,
    });
  }
}

const siteService = new SiteService();

export default siteService;

import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Divider, message } from 'antd';

import Base from 'components/Base';
import UserTable from 'pages/Users/components/UserTable/UserTable';
import UserModal from 'pages/Users/components/UserModal/UserModal';
import siteService from 'service/SiteService';

@inject('appStore', 'userFormStore')
@observer
class Users extends Base {
  componentDidMount() {
    this.s('app').selectedItem = 'users';
  }

  handleDelete = (id) => {
    siteService
      .api(this.s('app').token)
      .deleteUser(id)
      .then(this.handleResponse)
      .then(() => {
        message.success('Użytkownik poprawnie usunięty');
        this.s('app').update();
      })
      .catch(this.handleResponseError('Nie można usunąć użytkownika!'));
  }

  handleAction = (id, action) => {
    if (action === 'delete') {
      this.handleDelete(id);
      return;
    }
    this.s('userForm').userId = id;
    this.s('userForm').action = action;
    this.s('userForm').opened = true;
  }

  handleNew = () => {
    this.handleAction(0, 'new');
  }

  render() {
    return (
      <div>
        <UserModal id={this.modalId} action={this.modalType} />
        <Button
          type="primary"
          onClick={this.handleNew}
        >
          Dodaj użytkownika
        </Button>
        <Divider type="horizontal" />
        <UserTable
          onAction={this.handleAction}
          updateCount={this.s('app').updateCount}
        />
      </div>
    );
  }
}

export default Users;

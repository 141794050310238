import React from 'react';
import {
  Table, Button, Divider, Popconfirm,
} from 'antd';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import Base from 'components/Base';
import siteService from 'service/SiteService';

function heandleDelete(handler, id) {
  return () => {
    handler(id, 'delete');
  };
}

@inject('appStore')
@observer
class UserTable extends Base {
  @observable users = [];

  @observable roles = [];

  constructor(props) {
    super(props);
    const { handleClick } = this;
    const { onAction } = this.props;
    this.columns = [
      {
        title: 'Nazwa',
        dataIndex: 'Name',
        key: 'name',
      },
      {
        title: 'Email',
        dataIndex: 'Email',
        key: 'email',
      },
      {
        title: 'Aktywny',
        dataIndex: 'Enabled',
        key: 'enabled',
        render: v => (
          <span>
            {Number(v) === 0 ? 'Nie' : 'Tak'}
          </span>
        ),
      },
      {
        title: 'Rola',
        dataIndex: 'Role',
        key: 'role',
        render: this.getGroup,
      },
      {
        title: 'Akcje',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button
              onClick={handleClick}
              data-id={record.Uid}
              data-action="edit"
            >
              Edytuj
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Czy jesteś pewnien, że chcesz usunąć tego użytkownika?"
              onConfirm={heandleDelete(onAction, record.ID)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="danger"
              >
                Usuń
              </Button>
            </Popconfirm>
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.loadGroups(this.loadUsers);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateCount === this.props.updateCount) {
      return;
    }
    this.loadUsers();
  }

  loadUsers = () => {
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .getUsers()
      .then(this.handleResponse)
      .then((son) => {
        this.s('app').loaded();
        this.users = son.map((row) => {
          const data = Object.assign(row, { key: row.ID });
          return data;
        });
      })
      .catch(this.handleResponseError('Nie udało się pobrać użytkowników'));
  }

  loadGroups = (callback) => {
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .getRoles()
      .then(this.handleResponse)
      .then((son) => {
        this.roles = son;
        this.s('app').loaded();
        if (typeof callback === 'function') {
          callback(son);
        }
      })
      .catch(this.handleResponseError('Nie można załadować danych'));
  }

  getGroup = (user) => {
    const role = this.roles.filter((group => (group.Id === user)));
    if (role[0] === undefined) {
      return '-';
    }
    return role[0].Name;
  }

  setRowProps = record => ({ rowKey: record.ID })

  handleClick = (e) => {
    e.preventDefault();
    this.props.onAction(
      e.target.getAttribute('data-id'),
      e.target.getAttribute('data-action'),
    );
  }

  render() {
    return (
      <Table
        dataSource={this.users}
        columns={this.columns}
        pagination={false}
        onRow={this.setRowProps}
      />
    );
  }
}

export default UserTable;

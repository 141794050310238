import React from 'react';
import { observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import {
  Table, Popconfirm, Button, Icon, message, Divider,
} from 'antd';
import moment from 'moment';
import { prettyMinutes } from 'utils/moment';

import siteService from 'service/SiteService';
import LogModal from 'components/LogModal/LogModal';
import LogBrowserForm from 'pages/LogBrowser/components/LogBrowserForm/LogBrowserForm.component';
import Base from 'components/Base/Base';

@inject('appStore')
@observer
class LogBrowser extends Base {
  columns = [
    {
      title: 'Id',
      dataIndex: 'ID',
      key: 'ID',
      render: id => `#${id}`,
    },
    {
      title: 'Dzień',
      dataIndex: 'Day',
      key: 'Day',
      render: day => moment(day).format('YYYY-MM-DD'),
    },
    {
      title: 'Zalogowany czas',
      dataIndex: 'Duration',
      key: 'Duration',
      render: minutes => prettyMinutes(minutes),
    },
    {
      title: 'Projekt',
      dataIndex: 'Pid',
      key: 'Pid',
      render: pid => this.projects[pid],
    },
    {
      title: 'Akcje',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, item) => (
        <>
          <Button size="small" onClick={this.handleEdit(item.ID)}>
            <Icon type="edit" />
          </Button>
          <Popconfirm
            title="Jesteś pewien, że chcesz usunąć ten wpis?"
            okText="Tak"
            onConfirm={this.handleDelete(item.ID)}
            cancelText="Nie"
          >
            <Button size="small">
              <Icon type="delete" />
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]

  @observable data = []

  @observable users = []

  @observable projects = {}

  @observable openedLogId = 0;

  @observable options = '{}';

  @observable pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    pageSizeOptions: ['10', '20', '40'],
    showSizeChanger: true,
  }

  handleDelete = id => () => {
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .deleteLog(id)
      .then(this.handleResponse)
      .then(() => {
        this.s('app').loaded();
        this.refreshLogs();
        message.success('Wpis został poprawnie usunięty.');
      })
      .catch(this.handleResponseError('Błąd podczas usuwania!'));
  }

  handleEdit = id => () => {
    this.s('app').logModalOpened = true;
    this.openedLogId = id;
  }

  refreshLogs = () => {
    this.getLogs(this.pagination.current, this.pagination.pageSize, this.options);
  }

  getLogs = (page, limit, data) => {
    this.s('app').loading();
    siteService.api(this.s('app').token)
      .getLogBrowser(page, limit, data)
      .then(this.handleResponse)
      .then((json) => {
        this.data.replace(json.Entries);
        this.pagination.total = json.TotalCount;
        this.pagination.pageSize = json.Limit;
        this.pagination.current = json.Page;
        this.s('app').loaded();
      })
      .catch(this.handleResponseError('Nie można pobrać wpisów!'));
  }

  handleOptionsChange = (options) => {
    const strOptions = JSON.stringify(options);
    this.page = 1;
    this.options = strOptions;
    this.getLogs(1, this.pagination.pageSize, strOptions);
  }

  handleTableChnage = (pagination) => {
    const { current, pageSize } = pagination;
    let page = current;
    if (this.pagination.pageSize !== pageSize) {
      page = 1;
      this.pagination.pageSize = pageSize;
    }

    this.pagination.current = page;
    this.getLogs(page, pageSize, this.options);
  }

  componentDidMount() {
    this.s('app').selectedItem = 'log_browser';
    this.loadProjects(() => {
      this.getLogs(1, this.pagination.pageSize, this.options);
    }, (projects) => {
      projects.forEach((project) => {
        this.projects[project.ID] = project.Name;
      });
    }, true);
  }

  render() {
    return (
      <div>
        <LogBrowserForm onFormSubmit={this.handleOptionsChange} />
        <Divider />
        <Table
          size="small"
          columns={this.columns}
          pagination={toJS(this.pagination)}
          dataSource={toJS(this.data)}
          rowKey={entry => entry.ID}
          onChange={this.handleTableChnage}
        />
        <LogModal
          logId={this.openedLogId}
          onSubmit={this.refreshLogs}
        />
      </div>
    );
  }
}

export default LogBrowser;

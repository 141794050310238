/* global document */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import appStore from 'store/AppStore';
import timerStore from 'store/TimerStore';
import userFormStore from 'store/UserFromStore';
import projectFormStore from 'store/ProjectFromStore';
import timesheetStore from 'store/TimesheetStore';
import { isJwtExpired } from 'utils/jwt';

import { getSession, getTimerForm, getOpenedForm } from 'utils/storage';

import App from 'components/App/App';
import * as serviceWorker from './serviceWorker';

import './index.scss';

const token = getSession();
if (!isJwtExpired(token)) {
  appStore.token = token;
}
timerStore.importJson(getTimerForm());
appStore.homepageOpenedForm = getOpenedForm();

const stores = {
  appStore,
  timerStore,
  userFormStore,
  projectFormStore,
  timesheetStore,
};

ReactDOM.render(<Provider {...stores}><App store={appStore} /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Form, Button,
} from 'antd';
import moment from 'moment';

import Base from 'components/Base';
import ConfirmTimePicker from 'components/ConfirmTimePicker/ConfirmTimePicker';
import siteService from 'service/SiteService';
import { getMinutesFromMidnight, checkInRange } from 'utils/moment';

const FormItem = Form.Item;

@inject('appStore', 'timesheetStore')
@observer
class TimesheetForm extends Base {
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err !== null) {
        return;
      }
      const StartTime = getMinutesFromMidnight(values.timeStart);
      const EndTime = getMinutesFromMidnight(values.timeEnd);
      const index = this.s('timesheet').addInOut(StartTime, EndTime) - 1;
      const data = {
        DayId: this.s('timesheet').DayId,
        Id: 0,
        StartTime,
        EndTime,
        Type: 1,
      };
      this.s('app').loading();
      siteService.api(this.s('app').token)
        .saveTimesheetEntry(JSON.stringify(data))
        .then(this.handleResponse)
        .then((json) => {
          this.s('timesheet').InOut[index].dbId = json.ID;
          this.s('app').loaded();
        })
        .catch(this.handleResponseError('Nie można zapisać danych!'));
    });
  }

  componentWillUpdate = (oldProps) => {
    const { dayId, form } = this.props;
    if (oldProps.dayId !== dayId) {
      form.resetFields(['timeStart', 'timeEnd']);
    }
  }

  validateDate = (rule, value, cb) => {
    if (!moment.isMoment(value)) {
      return cb(true);
    }
    const dayStart = this.s('timesheet').TimeStart;
    const dayEnd = this.s('timesheet').TimeEnd;
    if (!checkInRange(value, dayStart, dayEnd)) {
      return cb(true);
    }
    return cb();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="inline" onSubmit={this.handleSubmit}>
        <FormItem>
          {getFieldDecorator('timeStart', {
            rules: [{ message: 'Niepoprawny czas początkowy.', validator: this.validateDate }],
          })(
            <ConfirmTimePicker format="HH:mm" />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('timeEnd', {
            rules: [{ message: 'Niepoprawny czas końcowy.', validator: this.validateDate }],
          })(
            <ConfirmTimePicker format="HH:mm" />,
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit">Dodaj</Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(TimesheetForm);

import React from 'react';

import moment from 'moment';
import {
  List, Button, Icon, Popconfirm,
} from 'antd';

import History from 'components/History/History';

function deleteHandler(onDelete, id) {
  return (e) => {
    e.preventDefault();
    onDelete(id);
  };
}

function editHandler(onEdit, id) {
  return (e) => {
    e.preventDefault();
    onEdit(id);
  };
}

function LastEntriesItem(props) {
  const {
    item, projectName, onDelete, onEdit,
  } = props;
  return (
    <List.Item key={item.ID}>
      {moment(item.Day).format('DD-MM-YYYY HH:mm')}
    &nbsp;-&nbsp;
      <strong key={item.ID}>{projectName}</strong>
    &nbsp;-&nbsp;
      {History.parseTime(item.Duration)}
    &nbsp;&nbsp;
      <Button size="small" onClick={editHandler(onEdit, item.ID)}>
        <Icon type="edit" />
      </Button>
      <Popconfirm
        title="Jesteś pewien, że chcesz usunąć ten wpis?"
        onConfirm={deleteHandler(onDelete, item.ID)}
        okText="Tak"
        cancelText="Nie"
      >
        <Button size="small">
          <Icon type="delete" />
        </Button>
      </Popconfirm>
    </List.Item>);
}

export default LastEntriesItem;

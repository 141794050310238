import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable, toJS } from 'mobx';

import Descriptions from 'antd/lib/descriptions';
import Button from 'antd/lib/button';
import Typography from 'antd/lib/typography';
import List from 'antd/lib/list';
import message from 'antd/lib/message';

import Base from 'components/Base';
import siteService from 'service/SiteService';

const { Item } = Descriptions;

@inject('appStore')
@observer
class System extends Base {
  @observable rowCounts = []

  @observable incorrectEntries = []

  handleFix = () => {
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .getSystemIncorrectFix()
      .then(this.handleResponse)
      .then(() => {
        this.s('app').loaded();
        message.success('Dane zostały naprawione!');
      })
      .catch(this.handleResponseError('Nie można pobrać danych'));
  }

  handleScan = () => {
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .getSystemIncorrect()
      .then(this.handleResponse)
      .then((json) => {
        this.s('app').loaded();
        this.incorrectEntries.replace(json);
      })
      .catch(this.handleResponseError('Nie można pobrać danych'));
  }

  componentDidMount() {
    this.s('app').selectedItem = 'system';
    this.s('app').loading();
    siteService
      .api(this.s('app').token)
      .getSystemStats()
      .then(this.handleResponse)
      .then((json) => {
        this.s('app').loaded();
        this.rowCounts.replace(json);
      })
      .catch(this.handleResponseError('Nie można pobrać danych'));
  }

  render() {
    return (
      <div>
        <h2>Ilość wierszy w kolumnach</h2>
        <Descriptions
          column={2}
          bordered
        >
          {this.rowCounts.map(table => (
            <Item key={table.Name} label={table.Name}>{table.Amount}</Item>
          ))}
        </Descriptions>
        <h2>Dziennnik wejść</h2>
        <Button type="primary" onClick={this.handleScan}>Szukaj błędnych wpisów</Button>
        <List
          bordered
          dataSource={toJS(this.incorrectEntries)}
          renderItem={item => (
            <List.Item>
              {'Id: '}
              {item.Id}
              {' Zapisany czas: '}
              <Typography.Text mark>{item.UsedTime}</Typography.Text>
              {' Oczekiwana wartość: '}
              {item.UsedCalc}
            </List.Item>
          )}
        />
        <Button
          type="danger"
          disabled={this.incorrectEntries.length <= 0}
          onClick={this.handleFix}
        >
          Popraw błędne wpisy
        </Button>
      </div>
    );
  }
}

export default System;

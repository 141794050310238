import { observable, action, toJS } from 'mobx';
import moment from 'moment';

import { getMinutesFromMidnight } from '../utils/moment';


export class TimesheetStore {
  @observable DayId = 0;

  @observable Day = '';

  @observable TimeStart = '';

  @observable TimeEnd = '';

  @observable Projects = [];

  @observable InOut = [];

  @observable iterator = 0;

  @observable dayChanged = false;

  setValues(data) {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      this.setValue(key, data[key]);
    });
  }

  @action.bound
  addInOut(start, end) {
    this.iterator += 1;
    return this.InOut.push({
      id: 0,
      dbId: 0,
      start,
      end,
    });
  }

  @action.bound
  deleteInOut(dbId) {
    this.InOut.replace(this.InOut.filter(row => row.dbId !== dbId));
  }

  @action.bound
  reset() {
    this.dayChanged = false;
    this.DayId = 0;
    this.Day = moment().format('YYYY-MM-DD');
    this.TimeStart = 0;
    this.TimeEnd = 0;
    this.Projects.replace([]);
    this.InOut.replace([]);
  }

  @action.bound
  loadFromJson(data) {
    this.DayId = data.ID;
    this.Day = moment(data.Day).format('YYYY-MM-DD');
    this.TimeStart = data.StartTime;
    this.TimeEnd = data.EndTime;
    this.Projects = [];
    if (data.Projects !== null) {
      const Projects = data.Projects.map(item => item.ID);
      this.Projects.replace(Projects);
    }
    if (data.Entries !== null) {
      let index = 0;
      this.InOut.replace(data.Entries.map(row => ({
        start: row.StartTime,
        end: row.EndTime,
        // eslint-disable-next-line
        id: index++,
        dbId: row.ID,
      })));
    } else {
      this.InOut.replace([]);
    }
  }

  getObject() {
    const data = {
      ID: this.DayId,
      StartTime: this.TimeStart,
      EndTime: this.TimeEnd,
      Projects: toJS(this.Projects),
    };
    return data;
  }

  @action.bound
  setValue(key, value) {
    switch (key) {
      case 'Day':
        this.Day = value.format('YYYY-MM-DD');
        break;
      case 'TimeStart':
        this.TimeStart = getMinutesFromMidnight(value);
        break;
      case 'TimeEnd':
        this.TimeEnd = getMinutesFromMidnight(value);
        break;
      case 'Projects':
        this.Projects = value;
        break;

      default:
        break;
    }
  }
}


const timesheetStore = new TimesheetStore();

export default timesheetStore;

import moment from 'moment';

export function getMinutesFromMidnight(date) {
  if (!moment.isMoment(date)) {
    return -1;
  }
  const mmtMidnight = date.clone().startOf('day');
  return date.diff(mmtMidnight, 'minutes');
}

export function getHour(minutes) {
  if (Number(minutes) === 0) {
    return 0;
  }
  return Math.floor(minutes / 60);
}

export function getMinutes(minutes) {
  if (Number(minutes) === 0) {
    return 0;
  }
  return minutes % 60;
}

export function formatMinutes(minutes) {
  let hour = getHour(minutes);
  let minute = getMinutes(minutes);
  minute = minute < 10 ? `0${minute}` : String(minute);
  hour = hour < 10 ? `0${hour}` : String(hour);
  return `${hour}:${minute}`;
}

export function prettyMinutes(totalMinutes, short) {
  const hour = getHour(totalMinutes);
  const minute = getMinutes(totalMinutes);
  let hourText = '';
  if (hour === 1 && hour !== 0) {
    hourText = `${hour}${short ? 'h' : ' godzina'}`;
  } else if (hour <= 4) {
    hourText = `${hour}${short ? 'h' : ' godziny'}`;
  } else if (hour >= 5 || hour === 0) {
    hourText = `${hour}${short ? 'h' : ' godzin'}`;
  }
  if (minute <= 0) {
    return hourText;
  }
  let minutesText = '';
  if (minute === 1) {
    minutesText = `${minute}${short ? 'm' : ' minuta'}`;
  } else if (minute <= 4) {
    minutesText = `${minute}${short ? 'm' : ' minuty'}`;
  } else if (minute >= 5) {
    minutesText = `${minute}${short ? 'm' : ' minut'}`;
  }
  return `${hourText}${short ? ' ' : ' i '}${minutesText}`;
}

export function checkInRange(value, start, end) {
  let data = value;
  if (moment.isMoment(value)) {
    data = getMinutesFromMidnight(value);
  }
  return (data > start && data < end);
}

export default {
  getMinutesFromMidnight,
  formatMinutes,
  getHour,
  getMinutes,
  checkInRange,
  prettyMinutes,
};

import React from 'react';
import { observer, inject } from 'mobx-react';
import {
  Row, Col,
} from 'antd';

import Base from 'components/Base';
import History from 'components/History/History';
import TimesheetCalendar from 'components/TimesheetCalendar/TimesheetCalendar';

@inject('appStore')
@observer
class Home extends Base {
  componentDidMount() {
    this.s('app').selectedItem = 'home';
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs={6} md={6} lg={6}>
            <h2>Zalogowany czas</h2>
            <History updateCount={this.s('app').updateCount} />
          </Col>
          <Col xs={6} md={6} lg={6}>
            <TimesheetCalendar />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Home;

import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import {
  Modal, Form, InputNumber, Button, Row, Col,
} from 'antd';

const FormItem = Form.Item;

@observer
class PickUpTime extends React.Component {
  @observable minutes = 0;

  @observable hours = 1;

  getValue = () => {
    let value = this.minutes;
    value += this.hours <= 0 ? 0 : this.hours * 60;
    return value;
  }

  handleOk = () => {
    const { onSubmit } = this.props;
    onSubmit(this.getValue());
  }

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel(false);
  }

  componentWillUpdate = (oldProps) => {
    const { enabled } = this.props;
    if (oldProps.enabled !== enabled) {
      this.minutes = 0;
      this.hours = 1;
    }
  }

  render() {
    const { enabled } = this.props;
    return (
      <Modal
        visible={enabled}
        maskClosable={false}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        destroyOnClose
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Zamknij
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleOk}>
            Zapisz
          </Button>,
        ]}
      >
        <br />
        <Form>
          <Row>
            <Col span={12}>
              <FormItem label="Godziny">
                <InputNumber
                  min={1}
                  max={24}
                  step={1}
                  value={this.hours}
                  onChange={(v) => { this.hours = v; }}
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Minuty">
                <InputNumber
                  min={0}
                  max={60}
                  step={1}
                  value={this.minutes}
                  onChange={(v) => { this.minutes = v; }}
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default PickUpTime;
